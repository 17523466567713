import Catalogue from "./Catalogue";
import Hero from "./common/Hero";
import Footer from "./common/Footer";
import Navbar from "./common/Navbar";
import { connect } from "react-redux";
import CustomerFormModal from "./common/CustomerFormModal";
import {
  createCustomerNameTextfieldValueChanged,
  createCustomerEmailTextfieldValueChanged,
  createCustomerContactTextfieldValueChanged,
  createCustomerAddressTextfieldValueChanged,
  addCustomerToSystem,
  getEcommerceData,
  ecommerceCartValueChanged,
} from "../../actions";
import { useEffect } from "react";

const Home = (props) => {

  // useEffect(() => {
  //   props.getEcommerceData();
  // }, []);

  if(!Object.keys(props.customer_details).length) {
    return (
      <div>
          <div className="absolute z-10 h-full top-0 bottom-0 left-0 right-0 bg-[rgba(255,255,255,0.9)]"></div>
          <CustomerFormModal
            customer_name={props.customer_name}
            customer_email={props.customer_email}
            customer_contact={props.customer_contact}
            customer_address={props.customer_address}
            nameTextfieldValueChanged={(event) => {
              props.createCustomerNameTextfieldValueChanged(event.target.value);
            }}
            emailTextfieldValueChanged={(event) => {
              props.createCustomerEmailTextfieldValueChanged(event.target.value);
            }}
            contactTextfieldValueChanged={(event) => {
              props.createCustomerContactTextfieldValueChanged(event.target.value);
            }}
            addressTextfieldValueChanged={(event) => {
              props.createCustomerAddressTextfieldValueChanged(event.target.value);
            }}
            submitButtonOnClick={(event) => {
              event.preventDefault();
              props.addCustomerToSystem(props.customer_name, props.customer_email, props.customer_contact, props.customer_address);
            }}
          />
        </div>
    )
  } else {
    return (
      <div className="home">
        <Navbar />
        <Hero />
        <h1 className="text-bold text-[1.5rem] text-gray-400 mb-4 mt-5">Catalogues</h1>
        <div className="Catalogue grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          {props.catalogue_data.map((item) => {
            return (
              <Catalogue
                title={item.catalogue_name}
                image={item.catalogue_image}
                item={item}
              />
            )
          })}
        </div>
        <Footer />
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    customer_name: state.customer.addCustomerNameEcommerce,
    customer_email: state.customer.addCustomerEmailEcommerce,
    customer_contact: state.customer.addCustomerContactEcommerce,
    customer_address: state.customer.addCustomerAddressEcommerce,
    customer_details: state.customer.customer_details,
    catalogue_data: state.inventory.catalogue_data,
    products_data: state.inventory.products_data,
    cart: state.ecommerce.cart,
  }
}

export default connect(mapStateToProps, {
  createCustomerNameTextfieldValueChanged,
  createCustomerEmailTextfieldValueChanged,
  createCustomerContactTextfieldValueChanged,
  createCustomerAddressTextfieldValueChanged,
  addCustomerToSystem,
  getEcommerceData,
  ecommerceCartValueChanged,
})(Home);
