const CustomerFormModal = (props) => {
  const {
    submitButtonOnClick,
    customer_name,
    customer_email,
    customer_contact,
    customer_address,
    nameTextfieldValueChanged,
    emailTextfieldValueChanged,
    contactTextfieldValueChanged,
    addressTextfieldValueChanged,
  } = props;

  return (
    <div className=" bg-[rgba(255,255,255,0.3)] p-4 fixed z-10 max-w-lg left-0 right-0 mx-auto">
      <h1 className="text-4xl text-center mb-4 bg-transparent">
        Enter Details
      </h1>
      <form
        className=" flex flex-col gap-3 bg-transparent items-center justify-between max-w-md mx-auto"
        onSubmit={submitButtonOnClick}
      >
        <div className="w-full flex flex-col gap-3 bg-transparent ">
          <label htmlFor="name" className="bg-transparent">
            {" "}
            Full name
          </label>
          <input
            id="name"
            className="max-w-full border-2 border-black my-1 px-3 py-2 bg-transparent "
            type="text"
            placeholder="Enter Full Name"
            value={customer_name}
            onChange={nameTextfieldValueChanged}
            required
          />
        </div>

        {/* <div className="flex flex-col gap-3 bg-transparent">
                    <label htmlFor="email" className="bg-transparent">Email</label>
                    <input
                        id="email"
                        className="w-full border-2 my-1 px-3 py-2 border-black bg-transparent"
                        type="email"
                        placeholder="your@email.com"
                        value={customer_email}
                        onChange={emailTextfieldValueChanged}
                        required
                    />
                    </div> */}
        <div className="w-full flex flex-col gap-3 bg-transparent">
          <label htmlFor="phone" className="bg-transparent">
            Phone number
          </label>
          <input
            id="phone"
            className="max-w-full border-2 border-black my-1 px-3 py-2 bg-transparent "
            type="number"
            placeholder="Your Phone Number"
            value={customer_contact}
            onChange={contactTextfieldValueChanged}
            required
          />
        </div>

        <div className="flex flex-col gap-3 justify-left bg-transparent">
          <label htmlFor="address" className="bg-transparent">
            Address
          </label>
          <textarea
            rows={3}
            cols={55}
            id="address"
            className="w-full border-2 my-1 px-3 py-2 bg-transparent border-black"
            type="text"
            placeholder="Your Address"
            value={customer_address}
            onChange={addressTextfieldValueChanged}
            required
          />
        </div>

        <button className="rounded-lg bg-blue-700 px-2 py-3 text-white w-full ">
          Submit Details
        </button>
      </form>
    </div>
  );
};

export default CustomerFormModal;
