import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pencil, Trash2, MoreVertical } from "lucide-react";

const SubCategoryCard = (props) => {
  const [actionopen, setactionopen] = useState(false);
  const [modalisopen, setmodalisopen] = useState(false);
  const navigate = useNavigate();
  const {
    subCategoryName,
    subCategoryID,
    deleteButtonOnPress,
  } = props;
  const data = {
    id: subCategoryID,
  }
  return (
    <button
      className="px-4 py-2 flex flex-row-reverse justify-between border rounded-lg"
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          setactionopen((prev) => !prev);
        }}
        id="dropdownDefaultButton"
        className="child relative text-white focus:outline-none font-medium rounded-lg text-sm"
      >
        <MoreVertical />
      </button>
      <h3>{subCategoryName}</h3>
      {actionopen && (
        <div className="z-5 flex flex-col gap-3 absolute rounded-lg shadow p-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              navigate("/admin/editsubcategory", {state: data});
            }}
            className="flex gap-2"
          >
            <Pencil />
            Edit
          </button>{" "}
          <button
            onClick={deleteButtonOnPress}
            className="flex gap-2"
          >
            <Trash2 />
            Delete
          </button>
        </div>
      )}
    </button>
  );
};

export default SubCategoryCard;
