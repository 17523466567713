import { Provider } from "react-redux"
import MainAppRoutes from "./routes/MainAppRoutes";
import store from "./reducers";

const App = () => {
  return (
    <Provider store={store}>
      <MainAppRoutes />
    </Provider>
  )
}

export default App;