import { configureStore } from "@reduxjs/toolkit";
import CustomerReducer from "./CustomerReducer";
import EcommerceReducer from "./EcommerceReducer";
import InventoryReducer from "./InventoryReducer";
import OrdersReducer from "./OrdersReducer";
import ProfileReducer from "./ProfileReducer";

export default configureStore({
    reducer: {
        profile: ProfileReducer,
        customer: CustomerReducer,
        inventory: InventoryReducer,
        orders: OrdersReducer,
        ecommerce: EcommerceReducer,
    }
});