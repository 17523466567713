// Profile Actions
export const USERNAME_TEXTFIELD_VALUE_CHANGED = "username_textfield_value_changed";
export const PASSOWRD_TEXTFIELD_VALUE_CHANGED = "password_textfield_value_changed";
export const CHANGE_PASSWORD_TEXTFIELD_VALUE_CHANGED = "change_password_textfield_value_changed";

// Order Actions
export const SET_ORDERS_DATA = "set_orders_data";
export const START_DATE_VALUE_CHANGED = "start_date_value_changed";
export const END_DATE_VALUE_CHANGED = "end_date_value_changed";
export const ORDERS_SEARCH_TEXTFIELD_VALUE_CHANGED = "orders_search_textfield_value_changed";
export const SORT_TYPE_VALUE_CHANGED = "sort_type_value_changed";
export const ADD_ORDERS_CATALOGUE_DROPDOWN_VALUE_CHANGED = "add_order_catalogue_dropdown_value_changed";
export const ADD_ORDERS_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED = "add_orders_customer_name_textfield_value_changed";
export const ADD_ORDERS_NUMBER_OF_PRODUCTS_VALUE_CHANGED = "add_orders_number_of_products_value_changed";
export const ADD_INPUT_FIELDS_VALUE_CHANGED = "add_input_fields_value_changed";
export const ADD_ORDERS_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED = "add_orders_customer_contact_textfield_value_changed";
export const ADD_ORDERS_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED = "add_orders_customer_email_textfield_value_changed";
export const ADD_ORDERS_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED = "add_orders_customer_address textfield_value_changed";
export const ADD_ORDERS_NOTES_TEXTFIELD_VALUE_CHANGED = "add_orders_notes_textfield_value_changed";
export const EDIT_ORDERS_QUANTITY_TEXTFIELD_VALUE_CHANGED = "edit_orders_quantity_textfield_value_changed";

// Customer Actions
export const SET_CUSTOMERS_DATA = "set_customers_data";
export const CUSTOMERS_SEARCH_TEXTFIELD_VALUE_CHANGED = "customers_search_textfield_value_changed";
export const ADD_CUSTOMERS_NAME_TEXTFIELD_VALUE_CHANGED = "add_customers_name_textfield_value_changed";
export const ADD_CUSTOMERS_EMAIL_TEXTFIELD_VALUE_CHANGED = "add_customers_email_textfield_value_changed";
export const ADD_CUSTOMERS_CONTACT_TEXTFIELD_VALUE_CHANGED = "add_customers_contact_textfield_value_changed";
export const ADD_CUSTOMERS_ADDRESS_TEXTFIELD_VALUE_CHANGED = "add_customers_address_textfield_value_changed";
export const SET_LOGIN_VARIABLE = "set_login_variable";

export const CREATE_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED = "create_customer_name_textfield_value_changed";
export const CREATE_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED = "create_customer_email_textfield_value_changed";
export const CREATE_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED = "create_customer_contact_textfield_value_changed";
export const CREATE_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED = "create_customer_address_textfield_value_changed";
export const TOGGLE_CUSTOMER_DETAILS_MODAL = "toggle_customer_details_modal";
export const SET_CUSTOMER_DETAILS = "set_customer_details";
export const ECOMMERCE_CUSTOMER_ID_VALUE_CHANGED = "ecommerce_customer_id_value_changed";
export const TOGGLE_ECOMMERCE_LOADER = "toggle_ecommerce_loader";
export const ECOMMERCE_INDEX_VALUE_CHANGED = "ecommerce_index_value_changed";
export const ECOMMERCE_REMOVE_INDEX_VALUE_CHANGED = "ecommerce_remove_index_value_changed";
export const ECOMMERCE_CART_PRODUCTS_VALUE_CHANGED = "ecommerce_cart_product_value_changed";
export const ECOMMERCE_CART_QUANTITY_VALUE_CHANGED = "ecommerce_cart_quantity_value_changed";
export const ECOMMERCE_SET_BILL_TOTAL = "ecommerce_set_bill_total";
export const ECOMMERCE_TOTAL_ITEMS_VALUE_CHANGED = "ecommerce_total_items_value_changed";

// Inventory Actions
export const GET_INVENTORY_DATA = "get_inventory_data";
export const PRODUCTS_SEARCH_TEXTFIELD_VALUE_CHANGED = "products_search_textfield_value_changed";
export const ADD_PRODUCTS_NAME_TEXTFIELD_VALUE_CHANGED = "add_products_name_textfield_value_changed";
export const ADD_PRODUCTS_PRICE_TEXTFIELD_VALUE_CHANGED = "add_products_price_textfield_value_changed";
export const ADD_PRODUCTS_QUANTITY_TEXTFIELD_VALUE_CHANGED = "add_products_quantity_textfield_value_changed";
export const ADD_PRODUCTS_THRESHOLD_TEXTFIELD_VALUE_CHANGED = "add_products_threshold_textfield_value_changed";
export const ADD_PRODUCTS_CATALOGUE_DROPDOWN_VALUE_CHANGED = "add_products_catalogue_dropdown_value_changed";
export const ADD_PRODUCTS_CATEGORY_DROPDOWN_VALUE_CHANGED = "add_products_category_dropdown_value_changed";
export const ADD_PRODUCTS_SUB_CATEGORY_DROPDOWN_VALUE_CHANGED = "add_products_sub_category_dropdown_value_changed";
export const ADD_PRODUCTS_IMAGE_VALUE_CHANGED = "add_products_image_value_changed";
export const ADD_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED = "add_catalogue_name_textfield_value_changed";
export const ADD_CATALOGUE_IMAGE_VALUE_CHANGED = "add_catalogue_image_value_changed";
export const ADD_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED = "add_category_name_textfield_value_changed";
export const ADD_CATEGORY_CATALOGUE_DROPDOWN_VALUE_CHANGED = "add_category_catalogue_dropdown_value_changed";
export const ADD_CATEGORY_IMAGE_VALUE_CHANGED = "add_category_image_value_changed";
export const ADD_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED = "add_sub_category_name_textfield_value_changed";
export const ADD_SUB_CATEGORY_CATEGORY_DROPDOWN_VALUE_CHANGED = "add_sub_category_category_dropdown_value_changed";
export const ADD_SUB_CATEGORY_CATALOGUE_VALUE_CHANGED = "add_sub_category_catalogue_value_changed";
export const ADD_SUB_CATEGORY_IMAGE_VALUE_CHANGED = "add_sub_category_image_value_changed";
export const SET_CATALOGUE_DATA = "set_catalogue_data";
export const SET_CATEGORY_DATA = "set_category_data";
export const SET_SUB_CATEGORY_DATA = "set_sub_category_data";
export const SET_PRODUCTS_DATA = "set_products_data";
export const EDIT_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED = "edit_catalogue_name_textfield_value_changed";
export const EDIT_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED = "edit_category_name_textfield_value_changed";
export const EDIT_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED = "edit_sub_category_name_textfield_value_changed";

export const ECOMMERCE_PRODUCT_SEARCH_TEXTFIELD_VALUE_CHANGED = "ecommerce_product_search_textfield_value_changed";
export const ECOMMERCE_CART_COUNTER_VALUE_CHANGED = "ecommerce_cart_counter_value_changed";
export const ECOMMERCE_CART_VALUE_CHANGED = "ecommerce_cart_value_changed";