import {
  Pencil,
  Trash2,
  User,
  MapPin,
  Phone,
  CalendarDays,
  Mail,
  MoreVertical,
} from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const CustomerCard = (props) => {
  const {
    customerId,
    customerName,
    customerContact,
    customerAddress,
    customerEmail,
    customerDate,
    customerDetails,
    buttonOnPress,
    deleteButtonOnPress,
  } = props;
  const [actionopen, setactionopen] = useState(false);
  const [modalisopen, setmodalisopen] = useState(false);
  const navigate = useNavigate();

  const handleedit = () => {
    setactionopen((prev) => !prev);
    navigate("/admin/editcustomer", { state: customerDetails });
  };

  return (
    <div key={customerId}>
      <div className="border rounded-lg p-4">
        <div className="relative flex flex-row-reverse justify-between">
          <button
            onClick={() => setactionopen((prev) => !prev)}
            id="dropdownDefaultButton"
            className="child relative text-white focus:outline-none font-medium rounded-lg text-sm"
          >
            <MoreVertical />
          </button>
          <h3 className="text-2xl text-center my-1 text-gray-300">
            {customerName}
          </h3>

          {actionopen && (
            <div className="z-5 flex flex-col gap-3 absolute rounded-lg shadow p-2">
              <button onClick={handleedit} className="flex gap-2">
                <Pencil />
                Edit
              </button>{" "}
              <button onClick={deleteButtonOnPress} className="flex gap-2">
                <Trash2 />
                Delete
              </button>
            </div>
          )}
        </div>
        <div className="flex gap-2 w-full">
          <MapPin className="h-4 w-5 text-gray-300" />
          <p className="text-gray-300">{customerAddress}</p>
        </div>
        <p className="flex items-center  gap-2 mt-2 text-gray-300">
          <Phone className="h-4 w-5" />
          {customerContact}
        </p>
        {/* <p className="flex  gap-2 items-center text-gray-300">
          <Mail className="h-4 w-5 " />
          {customerEmail}
        </p> */}
        <div className="flex justify-between mt-4">
          <p className="text-xs text-slim flex gap-2 text-gray-300">
            <CalendarDays className="h-4 w-5" />
            {customerDate}
          </p>
          {/* <button
            onClick={buttonOnPress}
            className="text-gray-300 hover:text-blue-500"
          >
            View orders
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default CustomerCard;
