import Navbar from "./common/Navbar";
import { connect } from "react-redux";
import {
  editSubCategoryName,
  editSubCategoryNameTextfieldValueChanged
} from "../../actions";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const EditSubcategory = (props) => {

  const location = useLocation();
  var data = location.state;
  const navigate = useNavigate();

  if(props.loggedIn) {
    // code
    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="mt-8 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">
              Edit Subcategory
            </h1>
            <form
              className="flex flex-col gap-3 items-center justify-between max-w-md mx-auto"
              onSubmit={(event) => {
                event.preventDefault();
                props.editSubCategoryName(props.edit_sub_category_name, data.id);
                Navigate("/admin/catalogue");
              }}
            >
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="subcategory" className="text-gray-300">
                  Sub Category Name
                </label>
                <input
                  id="subcategory"
                  className="w-full border my-1 px-3 py-2 text-gray-300"
                  type="text"
                  value={props.edit_sub_category_name}
                  placeholder="Sub Category Name"
                  onChange={(e) => {
                    props.editSubCategoryNameTextfieldValueChanged(e.target.value)
                  }}
                  required
                />
              </div>
  
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    edit_sub_category_name: state.inventory.edit_sub_category_name,
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  editSubCategoryNameTextfieldValueChanged,
  editSubCategoryName,
})(EditSubcategory);
