// const baseURL = "http://192.168.1.6:3000";
// const baseURL = "http://localhost:3000";
const baseURL = "https://gurukripatrading.com";

export const getAllDataAPI = baseURL + "/getAllData"

// Inventory APIs
export const getCatalogueDetailsAPI = baseURL + "/getCatalogues";
export const updateCatalogueDetailsAPI = baseURL + "/updateCatalogues";
export const setCatalogueDetailsAPI = baseURL + "/setCatalogues";
export const deleteCatalogueAPI = baseURL + "/deleteCatalogues";
export const getCategoryDetailsAPI = baseURL + "/getCategories";
export const updateCategoryDetailsAPI = baseURL + "/updateCategories";
export const setCategoryDetailsAPI = baseURL + "/setCategories";
export const deleteCategorysAPI = baseURL + "/deleteCategories";
export const getSubCategoryDetailsAPI = baseURL + "/getSubCategories";
export const updateSubCategoryDetailsAPI = baseURL + "/updateSubCategories";
export const setSubCategoryDetailsAPI = baseURL + "/setSubCategories";
export const deleteSubCategorysAPI = baseURL + "/deleteSubCategories";
export const getProductDetailsAPI = baseURL + "/getProducts";
export const updateProductDetailsAPI = baseURL + "/updateProducts";
export const setProoductDetailsAPI = baseURL + "/setProducts";
export const deleteProductAPI = baseURL + "/deleteProducts";
export const getCartData = baseURL + "/getCartDetails";

// Customer APIs
export const getCustomerDetailsAPI = baseURL + "/getCustomers";
export const setCustomerDetailsAPI = baseURL + "/setCustomerDetails";
export const editCustomerDetailsAPI = baseURL + "/editCustomerDetails";
export const deleteCustomerDetailsAPI = baseURL + "/deleteCustomerDetails";
export const checkLoginDetailsAPI = baseURL + "/checkLoginDetails"

// OrderAPIs
export const getOrderDetailsAPI = baseURL + "/getOrderDetails";
export const updateOrderDetailsAPI = baseURL + "/updateIndividualOrderDetails";
export const setOrderDetailsAPI = baseURL + "/setOrderDetails";
export const getindividualOrderDetailsAPI = baseURL + "/getIndividualOrderDetails";
export const deleteOrderAPI = baseURL + "/deleteOrders";