import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AlertTriangle, ListPlus } from "lucide-react";
import { useNavigate } from "react-router-dom";
// import { isButtonElement } from "react-router-dom/dist/dom";
import Navbar from "./common/Navbar";
import ProductCard from "./common/ProductCard";
import {
  productsSearchTextfieldValueChanged,
  deleteProduct,
  getProducts,
} from "../../actions";

const Inventory = (props) => {
  const [modalisopen, setmodalisopen] = useState(false);
  const navigate = useNavigate();

  const handlesearch = (e) => {
    props.productsSearchTextfieldValueChanged(e.target.value);
  };

  useEffect(() => {
    props.getProducts();
  }, [props.deleteProduct])

  const filtereddata = props.productsData
    .filter((item) =>
      props.products_search === "" || props.products_search === null || props.products_search === undefined
        ? props.productsData
        : item.product_number.toString().toLowerCase().includes(props.products_search.toLowerCase().toString()) 
    )


  if(props.loggedIn) {
    // code
    return (
      <div className="pt-5">
        <Navbar />
        <div className="flex justify-between">
          <h1 className="text-[1.5rem] md:text-[2rem] font-bold leading-none text-white py-[2rem] ">
            Inventory
          </h1>
  
          <button
            className="flex py-[2rem] gap-2 text-gray-300"
            onClick={() => navigate("/admin/addinventory")}
          >
            <ListPlus />
            <h1 className="text-[1rem] md:text-[1.4rem] font-medium leading-none text-white ">
              Add
            </h1>
          </button>
        </div>
        <div className="max-w-full w-full flex items-center gap-4 mb-5">
          <form className="w-full flex items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <input
                type="text"
                id="simple-search"
                value={props.products_search}
                onChange={handlesearch}
                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Products"
                required
              />
            </div>
            <button
              type="submit"
              className="p-2.5 ml-2 text-sm font-medium text-white rounded-lg border border-white focus:outline-none"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </form>
        </div>
        <div className="grid sm:girid-cols-1 lg:grid-cols-4 gap-6">
          {filtereddata.sort((a, b) => (a.product_id > b.product_id ? 1 : -1)).map((item) => {
            return (
              <ProductCard
                itemId={item.id}
                itemDepartment={item.catalogue_data.catalogue_name}
                itemPrice={item.price}
                itemQuantity={item.quantity}
                itemImage={item.product_image}
                itemProductNumber={item.product_number}
                item={item}
                itemCategory={item.category_data.category_name}
                itemSubCategory={item.sub_category_data.sub_category_name}
                deleteButtonOnPress={(event) => {
                  props.deleteProduct(item.product_id, item.product_image);
                  event.stopPropagation();
                  window.location.reload(false)
                }}
              />
            );
          })}
          {modalisopen && (
            <div className="absolute top-0 left-0 right-0 mx-auto max-w-md  ">
              <div className=" flex flex-col justify-center">
                <div className="border-b-[2px] flex gap-4 items-center">
                  <AlertTriangle />
                  <h1 className="pt-4 pb-2 ">Disclaimer</h1>
                </div>
                <p className="py-2">
                  You are trying to delete an item from the inventory, if you
                  confirm this item will be removed from the inventory permanantly
                </p>
                <div className="flex justify-between my-2">
                  <button
                    onClick={() => setmodalisopen((prev) => !prev)}
                    className="py-2 border-[2px] px-5 rounded-lg"
                  >
                    Cancel
                  </button>
                  <button className="bg-red-600 py-2 border-[2px] px-5 rounded-lg">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    productsData: state.inventory.products_data,
    products_search: state.inventory.products_search,
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  productsSearchTextfieldValueChanged,
  deleteProduct,
  getProducts,
})(Inventory);
