import axios from "axios"
import { deleteCustomerDetailsAPI, editCustomerDetailsAPI, getCustomerDetailsAPI, setCustomerDetailsAPI } from "../extras/APIs"
import {
    ADD_CUSTOMERS_ADDRESS_TEXTFIELD_VALUE_CHANGED,
    ADD_CUSTOMERS_CONTACT_TEXTFIELD_VALUE_CHANGED,
    ADD_CUSTOMERS_EMAIL_TEXTFIELD_VALUE_CHANGED,
    ADD_CUSTOMERS_NAME_TEXTFIELD_VALUE_CHANGED,
    CUSTOMERS_SEARCH_TEXTFIELD_VALUE_CHANGED,
    SET_CUSTOMERS_DATA,
    CREATE_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED,
    CREATE_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED,
    CREATE_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED,
    CREATE_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED,
    SET_CUSTOMER_DETAILS,
    TOGGLE_CUSTOMER_DETAILS_MODAL,
    ECOMMERCE_CUSTOMER_ID_VALUE_CHANGED,
    ECOMMERCE_CART_VALUE_CHANGED,
} from "./ActionTypes";
import { getEcommerceData } from "./ProfileActions";

export const getCustomerDetails = () => {
    return (dispatch) => {
        axios({
            method: "GET",
            url: getCustomerDetailsAPI,
            withCredentials: true,
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: SET_CUSTOMERS_DATA,
                        payload: response.data.data
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const customerSearchTextfieldValueChanged = value => {
    return {
        type: CUSTOMERS_SEARCH_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addCustomersNameTextfieldValueChanged = value => {
    return {
        type: ADD_CUSTOMERS_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addCustomersEmailTextfieldValueChanged = value => {
    return {
        type: ADD_CUSTOMERS_EMAIL_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addCustomersContactTextfieldValueChanged = value => {
    return {
        type: ADD_CUSTOMERS_CONTACT_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addCustomersAddressTextfieldValueChanged = value => {
    return {
        type: ADD_CUSTOMERS_ADDRESS_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addCustomer = (customer_name, customer_contact, customer_email, customer_address) => {
    return (dispatch) => {
        var data = {
            customer_name: customer_name,
            customer_email: customer_email,
            customer_contact: customer_contact,
            customer_address: customer_address
        }

        axios({
            method: "POST",
            url: setCustomerDetailsAPI,
            data: data,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                if (response.data.success) {
                    getCustomerDetails()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const editCustomers = (customer_name, customer_contact, customer_email, customer_address, data) => {
    return (dispatch) => {
        if (!customer_name) {
            customer_name = data.name;
        }
        if(!customer_contact) {
            customer_contact = data.contact_number;
        }
        if(!customer_email) {
            customer_email = data.email;
        }
        if(!customer_address) {
            customer_address = data.address;
        }

        const formData = new FormData();
        formData.append("name", customer_name);
        formData.append("contact_number", customer_contact);
        formData.append("email", customer_email);
        formData.append("address", customer_address);
        formData.append("customer_id", data.customer_id);

        axios({
            method: "POST",
            url: editCustomerDetailsAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if(response.data.success) {
                    getCustomerDetails();
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const deleteCustomers = customer_id => {
    return (dispatch) => {
        var formData = new FormData();
        formData.append("customer_id", customer_id);
        console.log(customer_id);

        axios({
            method: "POST",
            url: deleteCustomerDetailsAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if(response.data.success) {
                    getCustomerDetails();
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const createCustomerNameTextfieldValueChanged = value => {
    return {
        type: CREATE_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const createCustomerEmailTextfieldValueChanged = value => {
    return {
        type: CREATE_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const createCustomerContactTextfieldValueChanged = value => {
    return {
        type: CREATE_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const createCustomerAddressTextfieldValueChanged = value => {
    return {
        type: CREATE_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const toggleCustomerDetailsModal = value => {
    return {
        type: TOGGLE_CUSTOMER_DETAILS_MODAL,
        payload: value
    }
}

export const addCustomerToSystem = (customer_name, customer_email, customer_contact, customer_address) => {
    return (dispatch) => {
        var data = {
            customer_name: customer_name,
            customer_email: customer_email,
            customer_contact: customer_contact,
            customer_address: customer_address,
            creation_date: new Date().toLocaleString(),
        };

        axios({
            method: "POST",
            url: setCustomerDetailsAPI,
            data: data,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if (response.data.success) {
                    dispatch ({
                        type: SET_CUSTOMER_DETAILS,
                        payload: data,
                    })

                    dispatch({
                        type: ECOMMERCE_CUSTOMER_ID_VALUE_CHANGED,
                        payload: response.data.customer_id,
                    })

                    dispatch(getEcommerceData());
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}