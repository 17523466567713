import Navbar from "./common/Navbar";
import CategoryCard from "./common/CategoryCard";
import { useLocation, useNavigate } from "react-router-dom";
import { ListPlus } from "lucide-react";
import { connect } from "react-redux";
import { useEffect } from "react";
import {
  getCategory,
  deleteCategory,
} from "../../actions";

const Category = (props) => {
  const navigate = useNavigate();
  var location = useLocation();
  var data = location.state;
  var filterredData = [];
  filterredData = props.category_data.filter((item) => item.catalogue_id.toString() === data.catalogue_id.toString());

  useEffect(() => {
    props.getCategory()
  }, [props.deleteCategory()])


  if(props.loggedIn) {
    // code
    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="flex justify-between">
          <h1 className="text-[1.5rem] md:text-[2rem] font-bold leading-none text-white py-[2rem] ">
            Categories
          </h1>
          <button
            className="flex py-[2rem] gap-2 text-gray-300"
            onClick={() => navigate("/admin/addcategory")}
          >
            <ListPlus />
            <h1 className="text-[1rem] md:text-[1.4rem] font-medium leading-none ">
              Add
            </h1>
          </button>
        </div>
        <div className="Catalogue grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          {filterredData.map((item) => {
            console.log(item)
            return (
              <CategoryCard
                title={item.category_name}
                category_data={item}
                deleteButtonOnPress={(event) => {
                  props.deleteCategory(item.category_id);
                  event.stopPropagation();
                  window.location.refresh(false);
                }}
              />
            )
          })}
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    category_data: state.inventory.category_data,
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  deleteCategory,
  getCategory,
})(Category);
