import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ListPlus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "./common/Navbar";
import OrderCard from "./common/OrderCard";

import {
  startDateValueChanged,
  endDateValueChanged,
  sortTypeValueChanged,
  ordersSearchTextfieldValueChanged,
  deleteOrders,
  getOrders,
} from "../../actions";

const Orders = (props) => {
  const [startdate, setstartdate] = useState(new Date());
  const [enddate, setenddate] = useState(new Date());
  const [isopen, setisopen] = useState(false);
  const [dateisopen, setdateisopen] = useState(false);
  const navigate = useNavigate();
  const goto = () => {
    navigate("/admin/addorders");
  };

  const handleCalendarClose = () => console.log(startdate);
  const handleCalendarOpen = () => console.log(enddate);

  useEffect(() => {
    props.getOrders();
  }, []);
  
  if(props.loggedIn) {
    // code
    return (
      <div
        className="pt-5"
        // className="max-w-[90vw] md:max-w-[95vw] mx-auto"
      >
        <Navbar />
        <div className="relative flex md:flex-row justify-between">
          <h1 className="text-[1.5rem] md:text-[2rem] font-bold leading-none text-white py-[2rem] ">
            Orders
          </h1>
          {/* <button className="flex py-[2rem] gap-2 text-gray-300" onClick={goto}>
            <ListPlus />
            <h1 className="text-[1rem] md:text-[1.4rem] font-medium leading-none ">
              Add
            </h1>
          </button> */}
        </div>
        {dateisopen && (
          <div className="absolute z-10 border-[2px] rounded-lg flex flex-col p-4 justify-start max-w-md">
            <div className="flex flex-col gap-2">
              <div className="parent flex flex-col items-start justify-center gap-2">
                <label htmlFor="start-date" className="mr-4 text-gray-400">
                  Start Date
                </label>
                <DatePicker
                  selected={props.start_date}
                  onChange={(startdate) => props.startDateValueChanged(startdate)}
                  onCalendarClose={handleCalendarClose}
                  onCalendarOpen={handleCalendarOpen}
                  className="border-[2px] px-2 py-1"
                  placeholderText="Start date"
                />
              </div>
              <div className="parent flex flex-col items-start justify-around gap-2">
                <label htmlFor="end_date" className="mr-4 text-gray-400">
                  End date
                </label>
                <DatePicker
                  selected={props.end_date}
                  onChange={(enddate) => props.endDateValueChanged(enddate)}
                  onCalendarClose={handleCalendarClose}
                  onCalendarOpen={handleCalendarOpen}
                  placeholderText="End date"
                  className="border-[2px] px-2 py-1"
                />
              </div>
            </div>
            <button
              onClick={() => setdateisopen((prev) => !prev)}
              className="border-[1px] rounded-lg px-4 py-2 mt-3"
            >
              Find Orders
            </button>
          </div>
        )}
        <div className="max-w-full w-full flex items-center gap-4">
          <button
            onClick={() => setisopen((prev) => !prev)}
            id="dropdownDefaultButton"
            className="w-[14%] relative border rounded-lg text-white focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center"
          >
            Sort
          </button>
          {isopen && (
            <div className="w-50 px-5 py-1 z-10 border-[1px] flex flex-col gap-2 absolute mb-[-31vh] md:mb-[-36vh] items-center rounded-lg shadow">
              <button
                onClick={() => {
                  props.sortTypeValueChanged("Date ");
                  setdateisopen(true);
                  setisopen((prev) => !prev);
                }}
                className="w-full"
              >
                Date - start/end
              </button>
              <button
                onClick={() => {
                  props.sortTypeValueChanged("Date - Ascending");
                  setisopen((prev) => !prev);
                }}
                className="w-full"
              >
                Date - Ascending
              </button>
              <button
                onClick={() => {
                  props.sortTypeValueChanged("Date - Descending");
                  setisopen((prev) => !prev);
                }}
                className="w-full"
              >
                Date - Descending
              </button>
              <button
                onClick={() => {
                  props.sortTypeValueChanged("Price - Ascending");
                  setisopen((prev) => !prev);
                }}
                className="w-full"
              >
                Price - Ascending
              </button>
              <button
                onClick={() => {
                  props.sortTypeValueChanged("Price - Descending");
                  setisopen((prev) => !prev);
                }}
                className="w-full"
              >
                Price - Descending
              </button>
            </div>
          )}
          <form className="w-full flex items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <input
                type="text"
                id="simple-search"
                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Orders"
                onChange={(event) =>
                  props.ordersSearchTextfieldValueChanged(event.target.value)
                }
                required
              />
            </div>
            <button
              type="submit"
              className="p-2.5 ml-2 text-sm font-medium text-white rounded-lg border border-white focus:outline-none"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </form>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 mt-3">
          {props.all_orders.map((item) => {
            return (
              <OrderCard
                itemId={item.order_id}
                itemName={item.customer_name}
                itemAddress={item.customer_address}
                itemEmail={item.customer_email}
                itemPhone={item.customer_contact}
                itemDate={item.date}
                itemTotal={item.bill_amount}
                item={item}
                onPressButton={() => {
                  navigate("/admin/order", {
                    state: item,
                  });
                }}
                deleteButtonOnPress={(event) => {
                  props.deleteOrders(item.order_id);
                  event.stopPropagation();
                  window.location.reload(false);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    orders_search: state.orders.orders_search,
    sort_type: state.orders.sort_type,
    start_date: state.orders.start_date,
    end_date: state.orders.end_date,
    all_orders: state.orders.all_orders,
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  startDateValueChanged,
  endDateValueChanged,
  sortTypeValueChanged,
  ordersSearchTextfieldValueChanged,
  deleteOrders,
  getOrders,
})(Orders);
