import { useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import Products from "./common/Products";
import Navbar from "./common/Navbar";
import { connect } from "react-redux";
import {
  getEcommerceData,
  ecommerceCartCounterValueChanged,
  ecommerceCartValueChanged,
  ecommerceIndexValueChanged,
  ecommerceRemoveIndexValueChanged,
  setCart,
  ecommerceBillTotalValueChanged,
  ecommerceTotalItemsValueChanged,
} from "../../actions";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Category = (props) => {
  var location = useLocation();
  var pageData = location.state;

  const navigate = useNavigate();

  const [search, setsearch] = useState("");
  const filtereddata = props.products_data
    .filter(
      (item) =>
        item.catalogue_id.toString() === pageData.catalogue_id.toString()
    )
    .filter((item) =>
      search == "" || search == null || search == undefined
        ? props.products_data
        : item.product_number
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase().toString())
    );

  useEffect(() => {
    // const fetchData = async () => {
    //   await props.getEcommerceData();
    // };
    // fetchData();

    var total = 0;
    var items = 0;

    props.cart.map((item) => {
      props.products_data.forEach((product) => {
        if (item.product_id.toString() === product.product_id.toString()) {
          if (item.quantity > 0) {
            items = items + 1;
            total = total + item.quantity * product.price;
          }
        }
      });
    });
    props.ecommerceBillTotalValueChanged(total);
    props.ecommerceTotalItemsValueChanged(items);
  }, []);

  const getQuantity = (product_val) => {
    let quantityVal = 0;
    props.cart.map((cartObj, index) => {
      if (cartObj.product_id.toString() === product_val.toString()) {
        quantityVal = cartObj.quantity;
      }
    });
    return quantityVal;
  };

  if (props.productLoaderVisible) {
    return (
      <div>
        <h2>Loading ...</h2>
      </div>
    );
  } else {
    return (
      <div className="min-h-[100vh] pb-20">
        <Navbar />
        <div className="max-w-full w-full flex items-center gap-4 mb-5">
          <form className="w-full flex items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <input
                type="text"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
                id="simple-search"
                className=" bg-white text-black border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Products-"
                required
              />
            </div>
            <button
              type="submit"
              className="p-2.5 ml-2 text-sm font-medium text-white rounded-lg border bg-blue-700 border-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              <svg
                className="w-4 h-4 bg-blue-700"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </form>
        </div>
        {/* <div className="subcatagory overflow-x-auto flex gap-2 my-2">
          {props.sub_category_data &&
            props.sub_category_data.filter((item) => item.catalogue_id.toString() === pageData.catalogue_id.toString()).map((item) => (
              <div>
                <h3 className="px-2 py-1 text-medium border rounded-[20px]">
                  {item.sub_category_name}
                </h3>
              </div>
            ))}
        </div> */}
        <div className="flex-column">
          <div className="mx-auto grid grid-cols-1 md:grid-cols-4 gap-4 gap-y-5">
            {filtereddata &&
              filtereddata  
                .sort((a, b) => (a.product_id > b.product_id ? 1 : -1))
                .map((item, index) => {
                  return (
                    <div key={item.prevPrice}>
                      <Products
                        title={item.product_number}
                        img={item.product_image}
                        price={item.price}
                        category={item.category_data.category_name}
                        subcategory={item.sub_category_data.sub_category_name}
                        item={item}
                        cartButtonOnPress={(event) => {
                          // products.push(item.product_id);
                          // quantity[index] = 1;
                          props.setCart(
                            props.cart,
                            item.product_id,
                            1,
                            props.products_data
                          );
                        }}
                        plusButtonOnPress={(event) => {
                          props.setCart(
                            props.cart,
                            item.product_id,
                            1,
                            props.products_data
                          );
                        }}
                        minusButtonOnPress={(event) => {
                          props.setCart(
                            props.cart,
                            item.product_id,
                            -1,
                            props.products_data
                          );
                        }}
                        // quantity={getQuantity(item.product_id)}
                        quantity={getQuantity(item.product_id)}
                      />
                    </div>
                  );
                })}
          </div>
          <div className="border-[2px] rounded-lg p-4 flex gap-5 text-gray-400 flex-1 justify-between sticky bottom-3 left-0 right-0 bg-opacity-100 z-10">
            <div className="flex flex-[0.2] justify-around">
              <div className="flex-col align-start justify-start ">
                <h1>Total</h1>
                <h1>Rs.{props.bill_total}</h1>
              </div>
              <div className="flex-col align-start justify-start ml-10">
                <h1>Items</h1>
                <h1>{props.total_items}</h1>
              </div>
            </div>
            <button
              className="flex py-2 px-4 rounded-lg border justify-self-end font-bold "
              onClick={() => navigate("/customer/cart")}
            >
              Cart
            </button>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    category_data: state.inventory.category_data,
    products_data: state.inventory.products_data,
    sub_category_data: state.inventory.sub_category_data,
    cart: state.ecommerce.cart,
    cart_counter: state.ecommerce.cart_counter,
    customer_id: state.inventory.customer_id,
    productLoaderVisible: state.ecommerce.productLoaderVisible,
    index: state.ecommerce.index,
    removeindex: state.ecommerce.removeindex,
    total_items: state.ecommerce.total_items,
    bill_total: state.ecommerce.bill_total,
  };
};

export default connect(mapStateToProps, {
  getEcommerceData,
  ecommerceCartCounterValueChanged,
  ecommerceCartValueChanged,
  ecommerceIndexValueChanged,
  ecommerceRemoveIndexValueChanged,
  setCart,
  ecommerceBillTotalValueChanged,
  ecommerceTotalItemsValueChanged,
})(Category);
