import axios from "axios"
import {
    deleteOrderAPI,
    getCartData,
    getOrderDetailsAPI,
    setOrderDetailsAPI,
    updateOrderDetailsAPI,
    updateProductDetailsAPI
} from "../extras/APIs"
import {
    ADD_INPUT_FIELDS_VALUE_CHANGED,
    ADD_ORDERS_CATALOGUE_DROPDOWN_VALUE_CHANGED,
    ADD_ORDERS_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED,
    ADD_ORDERS_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED,
    ADD_ORDERS_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED,
    ADD_ORDERS_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED,
    ADD_ORDERS_NUMBER_OF_PRODUCTS_VALUE_CHANGED,
    ECOMMERCE_CART_VALUE_CHANGED,
    ECOMMERCE_SET_BILL_TOTAL,
    ECOMMERCE_TOTAL_ITEMS_VALUE_CHANGED,
    EDIT_ORDERS_QUANTITY_TEXTFIELD_VALUE_CHANGED,
    END_DATE_VALUE_CHANGED,
    ORDERS_SEARCH_TEXTFIELD_VALUE_CHANGED,
    SET_ORDERS_DATA,
    SORT_TYPE_VALUE_CHANGED,
    START_DATE_VALUE_CHANGED
} from "./ActionTypes"

export const startDateValueChanged = value => {
    return {
        type: START_DATE_VALUE_CHANGED,
        payload: value,
    }
}

export const endDateValueChanged = value => {
    return {
        type: END_DATE_VALUE_CHANGED,
        payload: value,
    }
}

export const ordersSearchTextfieldValueChanged = value => {
    return {
        type: ORDERS_SEARCH_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const sortTypeValueChanged = value => {
    return {
        type: SORT_TYPE_VALUE_CHANGED,
        payload: value,
    }
}

export const addOrdersCatalogueDropdownValueChanged = value => {
    return {
        type: ADD_ORDERS_CATALOGUE_DROPDOWN_VALUE_CHANGED,
        payload: value
    }
}

export const addOrdersCustomerNameTextfieldValueChanged = value => {
    return {
        type: ADD_ORDERS_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addOrdersNumberOfProductsValueChanged = value => {
    return {
        type: ADD_ORDERS_NUMBER_OF_PRODUCTS_VALUE_CHANGED,
        payload: value,
    }
}

export const addOrdersInputFieldsValueChanged = value => {
    return {
        type: ADD_INPUT_FIELDS_VALUE_CHANGED,
        payload: value,
    }
}

export const addOrdersCustomerContactTextfieldValueChanged = value => {
    return {
        type: ADD_ORDERS_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addOrdersCustomerEmailTextfieldValueChanged = value => {
    return {
        type: ADD_ORDERS_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addOrdersCustomerAddressTextfieldValueChanged = value => {
    return {
        type: ADD_ORDERS_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addOrdersNotesTextfieldValueChanged = value => {
    return {
        type: ADD_ORDERS_CATALOGUE_DROPDOWN_VALUE_CHANGED,
        payload: value,
    }
}

export const editOrdersQuantitytTextfieldValueChanged = value => {
    return {
        type: EDIT_ORDERS_QUANTITY_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const editOrder = (inputFields, data) => {
    return (dispatch) => {

        var product_array = [];
        var quantity_array = [];
        var bill_amount = 0;

        inputFields.map((item) => {
            product_array.push(item.product_id);
            quantity_array.push(item.quantity);
            let amount = item.price * item.quantity;
            bill_amount = bill_amount + amount;
        })
        
        var formData = new FormData();
        formData.append("customer_id", data.customer_id);
        formData.append("product_id", JSON.stringify(product_array));
        formData.append("quantity", JSON.stringify(quantity_array))
        formData.append("bill_amount", bill_amount);
        formData.append("date", data.date);
        formData.append("order_id", data.order_id);

        axios({
            method: "POST",
            url: updateOrderDetailsAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if(response.data.success) {
                    getOrders();
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const getOrders = () => {
    return (dispatch) => {
        axios({
            method: "GET",
            url: getOrderDetailsAPI,
            withCredentials: true,
        })
            .then(response => {
                if(response.data.success) {
                    dispatch({
                        type: SET_ORDERS_DATA,
                        payload: response.data.data.orders_data
                    });
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}
export const deleteOrders = (order_id) => {
    return (dispatch) => {
        var formData = new FormData();
        formData.append("order_id", order_id);

        axios({
            method: "POST",
            url: deleteOrderAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                if (response.data.success) {
                    getOrders();
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const setOrderDetails = (cart, customer_id, bill_amount, products) => {
    return (dispatch) => {
        let formData = new FormData();
        formData.append("customer_id", customer_id);
        formData.append("bill_amount", bill_amount);
        formData.append("cart", JSON.stringify(cart));
        var date = new Date();
        // console.log(date.toLocaleString());
        formData.append("date", date.toLocaleString());
        formData.append("products", JSON.stringify(products));

        axios({
            method: "POST",
            url: setOrderDetailsAPI,
            withCredentials: true,
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if(response.data.success) {
                    axios({
                        method: "GET",
                        url: getCartData,
                        withCredentials: true,
                    })
                        .then(response => {
                            if(response.data.success) {
                                dispatch({
                                    type: ECOMMERCE_CART_VALUE_CHANGED,
                                    payload: response.data.data,
                                })

                                dispatch({
                                    type: ECOMMERCE_SET_BILL_TOTAL,
                                    payload: 0,
                                })

                                dispatch({
                                    type: ECOMMERCE_TOTAL_ITEMS_VALUE_CHANGED,
                                    payload: 0
                                })
                            }
                        })
                        .catch(error => {
                            alert(error);
                        })
                }
                cart.map((element) => {
                    if(element.quantity > 0) {
                        products.map((item) => {
                            if(element.product_id.toString() === item.product_id.toString()) {
                                var quantity = item.quantity;
                                if (item.quantity > element.quantity) {
                                    quantity = item.quantity - element.quantity;
                                }
                                var object = {
                                    product_id: element.product_id,
                                    quantity: quantity,
                                    product_number: item.product_number,
                                    price: item.price,
                                    active: item.active,
                                    catalogue_id: item.catalogue_id,
                                    category_id: item.category_id,
                                    sub_category_id: item.sub_category_id,
                                    out_of_stock: item.out_of_stock,
                                    threshold: item.threshold,
                                    product_image: item.product_image,
                                }
                
                                axios({
                                    method: "POST",
                                    url: updateProductDetailsAPI,
                                    withCredentials: true,
                                    data: object,
                                    headers: {
                                        "Content-Type": "application/x-www-form-urlencoded",
                                    }
                                })
                                    .then(response => {
                                        if(response.data.success) {
                                            // code
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                            }
                        })
                    }
                })
            })
            .catch(error => {
                console.log(error);
            })
    }
}