import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TableListItem from "./TableListItem";

const Feed = (props) => {

  const {
    orders,
    products,
  } = props;


  return (
    <div className="mx-auto mt-[1rem] flex parent flex-col md:flex-row gap-3 md:gap-9 w-full">
      {/* left side */}
      <div className="flex child flex-col mx-auto max-w-[90vw] w-full md:max-w-[50%] p-4 border border-gray-200 rounded-lg shadow sm:p-8">
        <div className="flex items-center justify-between mb-4">
          <h5 className="text-xl font-bold leading-none text-white ">
            Latest Orders
          </h5>
          <Link
            to="/admin/orders"
            className="text-sm font-medium text-white hover:text-blue-500"
          >
            View all
          </Link>
        </div>
        <div className="flow-root">
          <ul role="list">
            <li className="border-b-[1px] border-white py-3 sm:py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-teal-200 truncate dark:text-teal-200">
                    Orders
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-teal-200 dark:text-white">
                  Order Amount
                </div>
              </div>
            </li>

            {orders.sort((a, b) => a.date < b.date ? 1 : -1).map((item, index) => {
              if(index >= 4) {
                return;
              }
              return (
                <TableListItem
                  customerName={item.customer_name}
                  customerEmail={item.date}
                  orderAmount={item.bill_amount}
                />
              )
            })}
          </ul>
        </div>
      </div>
      {/* Right side */}
      <div className="flex child flex-col w-full mx-auto max-w-[90vw] md:max-w-[50%] p-4 border border-gray-200 rounded-lg shadow sm:p-8 dark:border-gray-700">
        <div className="flex items-center justify-between mb-4">
          <h5 className="text-xl font-bold leading-none text-white dark:text-white">
            Inventory Count
          </h5>
          <Link
            to="/admin/inventory"
            className="text-sm font-medium text-white hover:text-blue-500 dark:text-blue-500"
          >
            View all
          </Link>
        </div>
        <div className="flow-root">
          <ul role="list">
            <li className="border-b-[1px] border-white py-3 sm:py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-teal-200 truncate dark:text-white">
                    Product
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-teal-200 dark:text-white">
                  Quantity
                </div>
              </div>
            </li>

            {products.sort((a, b) => a.quantity > b.quantity ? 1 : -1).map((items, index) => {
              if(index >= 4) {
                return;
              } else {
                return (
                  <TableListItem
                    customerName={items.product_number}
                    customerEmail={items.category_data.category_name}
                    orderAmount={items.quantity}
                  />
                )
              }
            })}
            {/* <TableListItem
              customerName={8}
              customerEmail="Department - 1"
              orderAmount={320}
            />
            <TableListItem
              customerName={10}
              customerEmail="Department - 2"
              orderAmount={3467}
            />
            <TableListItem
              customerName={125}
              customerEmail="Department - 1"
              orderAmount={67}
            />
            <TableListItem
              customerName={489}
              customerEmail="Department - 3"
              orderAmount={367}
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Feed;
