import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const Products = ({
  img,
  title,
  category,
  price,
  subcategory,
  item,
  cartButtonOnPress,
  plusButtonOnPress,
  minusButtonOnPress,
  counterVal,
  quantity,
}) => {
  const navigate = useNavigate();
  return (
    <div className=" h-[90Vh] z-1 border rounded-lg max-w-md w-full flex flex-col">
      <div className="max-h-[70%] h-full max-w-full flex bg-cover rounded-lg overflow-auto items-center justify-center">
        <img className="object-contain w-full h-full rounded-lg" src={img} />
        <p className="absolute rotate-[-45deg] text-white h-fit w-fit bg-transparent text-6xl opacity-40">
          Kamani Arts
        </p>
      </div>
      <div className="px-4 flex flex-col leading-normal mt-4">
        <h1 className=" font-bold text-xl mb-2">{title}</h1>
        <div className="flex gap-2">
          <p className="py-1 px-2 border rounded-lg text-gray-400 text-base mb-2">
            {category}
          </p>
          <p className="py-1 px-2 border rounded-lg text-gray-400 text-base mb-2">
            {subcategory}
          </p>
        </div>

        <div className="=text-sm flex justify-between items-center">
          <h1 className="text-bold text-xl ">Rs. {price}</h1>
          {quantity === 0 ? (
            <button
              onClick={cartButtonOnPress}
              className="relative z-10 px-4 py-3 border rounded-lg "
            >
              Add to Cart
            </button>
          ) : (
            <div className="border rounded-lg px-4 py-3 flex gap-5">
              <button onClick={minusButtonOnPress}>-</button>
              <h3>{quantity}</h3>
              <button onClick={plusButtonOnPress}>+</button>
            </div>
          )}
        </div>
        {/* <Link className=" flex mt-5" to="/product">
            <h3>View Product</h3>
          </Link> */}
      </div>
    </div>
    // </button>
  );
};

export default Products;
