import {
    ADD_INPUT_FIELDS_VALUE_CHANGED,
    ADD_ORDERS_CATALOGUE_DROPDOWN_VALUE_CHANGED,
    ADD_ORDERS_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED,
    ADD_ORDERS_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED,
    ADD_ORDERS_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED,
    ADD_ORDERS_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED,
    ADD_ORDERS_NOTES_TEXTFIELD_VALUE_CHANGED,
    ADD_ORDERS_NUMBER_OF_PRODUCTS_VALUE_CHANGED,
    END_DATE_VALUE_CHANGED,
    ORDERS_SEARCH_TEXTFIELD_VALUE_CHANGED,
    SET_ORDERS_DATA,
    SORT_TYPE_VALUE_CHANGED,
    START_DATE_VALUE_CHANGED
} from "../actions";

var INITIAL_STATE = {
    start_date: "",
    end_date: "",
    orders_search: "",
    sort_type: "",

    catalogue: "",
    customer_name: "",
    number_of_products: 1,
    inputFields: [
        {
            product: "",
            quantity: "",
            price: "",
        },
    ],
    customer_contact: "",
    customer_email: "",
    customer_address: "",
    order_notes: "",

    all_orders: [],
};

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case START_DATE_VALUE_CHANGED:
            return {...state, start_date: action.payload};
        case END_DATE_VALUE_CHANGED:
            return {...state, end_date: action.payload};
        case ORDERS_SEARCH_TEXTFIELD_VALUE_CHANGED:
            return {...state, orders_search: action.payload};
        case SORT_TYPE_VALUE_CHANGED:
            return {...state, sort_type: action.payload};
        case ADD_ORDERS_CATALOGUE_DROPDOWN_VALUE_CHANGED:
            return {...state, catalogue: action.payload};
        case ADD_ORDERS_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED:
            return {...state, customer_name: action.payload};
        case ADD_ORDERS_NUMBER_OF_PRODUCTS_VALUE_CHANGED:
            return {...state, number_of_products: action.payload};
        case ADD_INPUT_FIELDS_VALUE_CHANGED:
            return {...state, inputFields: action.payload};
        case ADD_ORDERS_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED:
            return {...state, customer_contact: action.payload};
        case ADD_ORDERS_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED:
            return {...state, customer_email: action.payload};
        case ADD_ORDERS_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED:
            return {...state, customer_address: action.payload};
        case ADD_ORDERS_NOTES_TEXTFIELD_VALUE_CHANGED:
            return {...state, order_notes: action.payload};
        case SET_ORDERS_DATA:
            return {...state, all_orders: [...action.payload]};
        default:
            return state;
    }
}