import Login from "../components/admin/Login";
import Home from "../components/admin/Home";
import Inventory from "../components/admin/Inventory";
import Customers from "../components/admin/Customers";
import Catalogue from "../components/admin/Catalogue";
import Category from "../components/admin/Category";
import Orders from "../components/admin/Orders";
import IndiOrder from "../components/admin/IndiOrder";
import AddCustomer from "../components/admin/AddCustomer";
import AddCatalogue from "../components/admin/AddCatalogue";
import AddCategory from "../components/admin/AddCategory";
import AddSubcategory from "../components/admin/AddSubcategory";
import CustomerOrder from "../components/admin/CustomerOrder";
import AddOrder from "../components/admin/AddOrder";
import AddInventory from "../components/admin/AddInventory";
import Previews from "../components/admin/Test";
import Profile from "../components/admin/Profile";
import EditOrder from "../components/admin/EditOrder";
import EditInventory from "../components/admin/EditInventory";
import EditCatalogue from "../components/admin/EditCatalogue";
import EditCategory from "../components/admin/EditCategory";
import EditSubcategory from "../components/admin/EditSubcategory";
import EditCustomer from "../components/admin/EditCustomer";
import SubCategory from "../components/admin/SubCategory";
import Bill from '../components/admin/Bill';
import { Routes, Route } from "react-router-dom";
import SignOut from "../components/admin/SignOut";

const AdminRoutes = () => {
    return (
        <div className="max-w-[90vw] md:max-w-[95vw] mx-auto min-h-screen max-h-auto">
            <Routes>
                {/* <Route path="/" Component={Login} /> */}
                <Route path="/" element={<Login />} />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/orders" element={<Orders />} />
                <Route exact path="/order" element={<IndiOrder />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route path="/inventory" element={<Inventory />} />
                <Route path="/customers" element={<Customers />} />
                <Route exact path="/catalogue" element={<Catalogue />} />
                <Route exact path="/catalogue/category" element={<Category />}/>
                <Route
                exact
                path="/catalogue/category/subcategory"
                element={<SubCategory />}
                />
                <Route path="/customerorder" element={<CustomerOrder />} />
                <Route path="/addcustomers" element={<AddCustomer />} />
                <Route path="/addcatalogue" element={<AddCatalogue />} />
                <Route path="/addcategory" element={<AddCategory />} />
                <Route path="/addsubcategory" element={<AddSubcategory />} />
                <Route path="/addorders" element={<AddOrder />} />
                <Route path="/addinventory" element={<AddInventory />} />
                <Route path="/Previews" element={<Previews />} />
                <Route path="/editcatalogue" element={<EditCatalogue />} />
                <Route path="/editcategory" element={<EditCategory />} />
                <Route path="/editsubcategory" element={<EditSubcategory />} />
                <Route path="/editorder" element={<EditOrder />} />
                <Route path="/editcustomer" element={<EditCustomer />} />
                <Route path="/editinventory" element={<EditInventory />} />
                <Route path="/signout" element={<SignOut />} />
                <Route exact path="/order/bill" element={<Bill />} />
            </Routes>
        </div>
    )
}

export default AdminRoutes;