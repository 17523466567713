import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Pencil, Trash2, MoreVertical } from "lucide-react";

const CatalogueCard = ({ title, image, catalogue_data, deleteButtonOnPress }) => {
  const [actionsopen, setactionsopen] = useState(false);
  const [modalisopen, setmodalisopen] = useState(false);
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate("/admin/catalogue/category", {state: catalogue_data})}>
      <div className="relative h-[20rem] bg-none block rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
        <img
          className="rounded-lg h-full object-cover w-full"
          src={image}
          alt=""
        />
        <div className="absolute top-0 p-6">
          <div className="relative flex ">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setactionsopen((prev) => !prev);
              }}
              id="dropdownDefaultButton"
              className="child relative text-white focus:outline-none font-medium rounded-lg text-sm"
            >
              <MoreVertical />
            </button>
            <h3 className="text-2xl text-center my-1 text-gray-300">{title}</h3>
          </div>
          {actionsopen && (
            <div className="z-5 flex flex-col gap-3 absolute rounded-lg shadow p-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/admin/editcatalogue", {state: catalogue_data});
                }}
                className="flex gap-2"
              >
                <Pencil />
                Edit
              </button>{" "}
              <button
                onClick={deleteButtonOnPress}
                className="flex gap-2"
              >
                <Trash2 />
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </button>
  );
};

export default CatalogueCard;
