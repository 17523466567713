import { Link } from "react-router-dom";

const CartCard = ({
  counter,
  setcounter,
  title,
  img,
  price,
  category,
  subcategory,
  minusButtonOnClick,
  plusButtonOnClick,
  removeButtonOnClick,
}) => {
  return (
    <article className=" rounded-lg border-[2px] max-w-md w-full flex gap-1">
      <div className="max-h-full max-w-[40%] flex-none bg-cover rounded-lg">
        <img className="object-contain h-full " src={img} />
      </div>
      <div className="px-2 py-2 flex flex-col justify-center leading-normal">
        <h1 className=" font-bold text-xl mb-2">{title}</h1>
        <div className="flex gap-2">
          <p className="py-1 px-2 border rounded-lg text-base mb-2">
            {category}
          </p>
          <p className="py-1 px-2 border rounded-lg text-base mb-2">
            {subcategory}
          </p>
        </div>
        <div className="mt-2 text-sm flex gap-5 items-center">
          <h1 className="text-bold text-xl">Rs. {price}</h1>
          <div className="border rounded-lg py-1 px-4 flex gap-5">
            <button onClick={minusButtonOnClick}>-</button>
            <h3>{counter}</h3>
            <button onClick={plusButtonOnClick}>+</button>
          </div>
        </div>
        <div className="flex pt-2 w-full">
          <button
            className="border rounded-lg px-2 py-1 w-full"
            onClick={removeButtonOnClick}
          >
            Remove
          </button>
          {/* <Link className="border rounded-lg py-1 px-2" to="/product">
            <h3>View Product</h3>
          </Link> */}
        </div>
      </div>
    </article>
  );
};

export default CartCard;
