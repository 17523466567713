import {
  Trash2,
  User,
  Pencil,
  MapPin,
  Phone,
  CalendarDays,
  Mail,
  MoreVertical,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const OrderCard = (props) => {
  const {
    itemId,
    itemName,
    itemAddress,
    itemPhone,
    itemEmail,
    itemDate,
    itemTotal,
    onPressButton,
    deleteButtonOnPress,
    item,
  } = props;

  const [actionopen, setactionopen] = useState(false);
  const [modalisopen, setmodalisopen] = useState(false);
  const navigate = useNavigate();
  return (
    <div key={itemId} className="flex flex-col justify-start text-white ">
      <button
        onClick={onPressButton}
        className="relative border rounded-lg p-4"
      >
        <div className="relative flex flex-row-reverse justify-between gap-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setactionopen((prev) => !prev);
            }}
            id="dropdownDefaultButton"
            className="child relative text-white focus:outline-none font-medium rounded-lg text-sm"
          >
            <MoreVertical />
          </button>
          <p className="text-sm text-white ">Order Id:{itemId}</p>{" "}
          {actionopen && (
            <div className="z-5 flex flex-col gap-3 absolute rounded-lg shadow p-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/admin/editorder", { state: item });
                }}
                className="flex gap-2"
              >
                <Pencil />
                Edit
              </button>{" "}
              <button onClick={deleteButtonOnPress} className="flex gap-2">
                <Trash2 />
                Delete
              </button>
            </div>
          )}
        </div>
        <h3 className="flex items-center gap-2 my-3 ">
          <User className="h-4 w-5" />
          {itemName}
        </h3>
        <p className="flex gap-2 w-full ">
          <MapPin className="h-4 w-5" />
          <span className="text-left">{itemAddress}</span>
        </p>
        <p className="flex items-center gap-2 mt-2 ">
          <Phone className="h-4 w-5" />
          {itemPhone}
        </p>
        {/* <p className="flex  gap-2 items-center ">
          <Mail className="h-4 w-5 " />
          {itemEmail}
        </p> */}
        <div className="flex justify-between mt-4 ">
          <p className="text-xs text-slim flex gap-2">
            <CalendarDays />
            {itemDate}
          </p>
          <h1>Rs.{itemTotal}</h1>
        </div>
      </button>
    </div>
  );
};

export default OrderCard;
