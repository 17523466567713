import {
    ADD_CATALOGUE_IMAGE_VALUE_CHANGED,
    ADD_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED,
    ADD_CATEGORY_CATALOGUE_DROPDOWN_VALUE_CHANGED,
    ADD_CATEGORY_IMAGE_VALUE_CHANGED,
    ADD_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
    ADD_PRODUCTS_CATALOGUE_DROPDOWN_VALUE_CHANGED,
    ADD_PRODUCTS_CATEGORY_DROPDOWN_VALUE_CHANGED,
    ADD_PRODUCTS_IMAGE_VALUE_CHANGED,
    ADD_PRODUCTS_NAME_TEXTFIELD_VALUE_CHANGED,
    ADD_PRODUCTS_PRICE_TEXTFIELD_VALUE_CHANGED,
    ADD_PRODUCTS_QUANTITY_TEXTFIELD_VALUE_CHANGED,
    ADD_PRODUCTS_SUB_CATEGORY_DROPDOWN_VALUE_CHANGED,
    ADD_PRODUCTS_THRESHOLD_TEXTFIELD_VALUE_CHANGED,
    ADD_SUB_CATEGORY_CATALOGUE_VALUE_CHANGED,
    ADD_SUB_CATEGORY_CATEGORY_DROPDOWN_VALUE_CHANGED,
    ADD_SUB_CATEGORY_IMAGE_VALUE_CHANGED,
    ADD_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
    EDIT_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED,
    EDIT_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
    EDIT_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
    PRODUCTS_SEARCH_TEXTFIELD_VALUE_CHANGED,
    SET_CATALOGUE_DATA,
    SET_CATEGORY_DATA,
    SET_PRODUCTS_DATA,
    SET_SUB_CATEGORY_DATA
} from "../actions";

var INITIAL_STATE = {
    products_search: "",

    add_product_name: "",
    add_product_price: "",
    add_product_quantity: "",
    add_product_catalogue: 0,
    add_product_category: 0,
    add_product_sub_category: 0,
    add_product_threshold: "",
    add_product_image: null,

    add_catalogue_name: "",
    add_catalogue_image: [],
    
    add_category_name: "",
    add_category_catalogue: "",
    add_category_image: null,

    add_sub_category_name: "",
    add_sub_category_category: "",
    add_sub_category_catalogue: "",
    add_sub_category_image: null,

    catalogue_data: [],
    category_data: [],
    sub_category_data: [],
    products_data: [],

    edit_catalogue_name: "",
    edit_category_name: "",
    edit_sub_category_name: "",
  };

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case PRODUCTS_SEARCH_TEXTFIELD_VALUE_CHANGED:
            return {...state, products_search: action.payload};
        case ADD_PRODUCTS_NAME_TEXTFIELD_VALUE_CHANGED:
            return {...state, add_product_name: action.payload};
        case ADD_PRODUCTS_PRICE_TEXTFIELD_VALUE_CHANGED:
            return {...state, add_product_price: action.payload};
        case ADD_PRODUCTS_QUANTITY_TEXTFIELD_VALUE_CHANGED:
            return {...state, add_product_quantity: action.payload};
        case ADD_PRODUCTS_CATALOGUE_DROPDOWN_VALUE_CHANGED:
            return {...state, add_product_catalogue: action.payload};
        case ADD_PRODUCTS_THRESHOLD_TEXTFIELD_VALUE_CHANGED:
            return {...state, add_product_threshold: action.payload};
        case ADD_PRODUCTS_CATEGORY_DROPDOWN_VALUE_CHANGED:
            return {...state, add_product_category: action.payload};
        case ADD_PRODUCTS_SUB_CATEGORY_DROPDOWN_VALUE_CHANGED:
            return {...state, add_product_sub_category: action.payload};
        case ADD_PRODUCTS_IMAGE_VALUE_CHANGED:
            return {...state, add_product_image: action.payload};
        case ADD_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED:
            return {...state, add_catalogue_name: action.payload};
        case ADD_CATALOGUE_IMAGE_VALUE_CHANGED:
            return {...state, add_catalogue_image: action.payload};
        case ADD_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED:
            return {...state, add_category_name: action.payload};
        case ADD_CATEGORY_CATALOGUE_DROPDOWN_VALUE_CHANGED:
            return {...state, add_category_catalogue: action.payload};
        case ADD_CATEGORY_IMAGE_VALUE_CHANGED:
            return {...state, add_category_image: action.payload};
        case ADD_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED:
            return {...state, add_sub_category_name: action.payload};
        case ADD_SUB_CATEGORY_CATEGORY_DROPDOWN_VALUE_CHANGED:
            return {...state, add_sub_category_category: action.payload};
        case ADD_SUB_CATEGORY_CATALOGUE_VALUE_CHANGED:
            return {...state, add_sub_category_catalogue: action.payload};
        case ADD_SUB_CATEGORY_IMAGE_VALUE_CHANGED:
            return {...state, add_sub_category_image: action.payload};
        case SET_CATALOGUE_DATA:
            return {...state, catalogue_data: action.payload};
        case SET_CATEGORY_DATA:
            return {...state, category_data: action.payload};
        case SET_SUB_CATEGORY_DATA:
            return {...state, sub_category_data: action.payload};
        case SET_PRODUCTS_DATA:
            return {...state, products_data: action.payload};
        case EDIT_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED:
            return {...state, edit_catalogue_name: action.payload};
        case EDIT_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED:
            return {...state, edit_category_name: action.payload};
        case EDIT_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED:
            return {...state, edit_sub_category_name: action.payload};
        default:
            return state;
    }
}