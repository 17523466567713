import Card from "./common/Card";
import Feed from "./common/Feed";
import Navbar from "./common/Navbar";
import { IndianRupee, BadgePercent, ShoppingCart } from "lucide-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllData
} from "../../actions";

const Home = (props) => {

  useEffect(() => {
    props.getAllData();
  }, []);

  const navigate = useNavigate();
  
  if(props.loggedIn) {
    return (
      <div className="pt-5">
        <Navbar />
        <div className="grid grid-cols-2 gap-[15px] mx-auto bg-[#09090B] mt-[10px] mt-6">
          <Card cardHeading={"Sales"} cardValue={props.all_orders ? props.all_orders.length : 0}>
            <BadgePercent color="white" />
          </Card>
          <Card cardHeading={"Products"} cardValue={props.products_data.length}>
            <ShoppingCart color="white" />
          </Card>
        </div>
        <Feed orders={props.all_orders} products={props.products_data} />
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    loggedIn: state.profile.loggedIn,
    all_orders: state.orders.all_orders,
    products_data: state.inventory.products_data,
  }
}

export default connect(mapStateToProps, {
  getAllData,
})(Home);
