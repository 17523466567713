const Card = (props) => {
  const {
    cardHeading,
    cardValue,
    children
  } = props;
  return (
    <div className=" flex flex-col border-[2px] border-white rounded-lg p-[1rem]">
      <div className="left flex justify-between pt-[10px]">
        <p className="text-medium text-white">{cardHeading}</p>
        {children}
      </div>
      <div className="right flex aligh-center">
        <h1 className="text-bold text-white text-[1.5rem] md:text-[2rem]">
          {cardValue}
        </h1>
      </div>
    </div>
  );
};

export default Card;
