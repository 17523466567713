import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "./common/Navbar";

const Profile = (props) => {

  const navigate = useNavigate()

  if(props.loggedIn) {
    // code
    return (
      <div className="p-5 h-screen">
        <Navbar />
        <h1 className="text-[1.5rem] md:text-[2rem] font-bold leading-none text-white py-[2rem] ">
          Change Password
        </h1>
        <div className="mt-6 grow flex items-center justify-start">
          <form
            className="flex flex-col gap-3 items-center justify-between max-w-md"
            onClick={() => {
              // code
            }}
          >
            <div className="w-full flex flex-col gap-3 ">
              <label htmlFor="npassword" className="text-gray-300">
                New Password
              </label>
              <input
                id="npassword"
                className="max-w-full border my-1 px-3 py-2 text-gray-300"
                type="password"
                placeholder="Enter new password"
                required
              />
            </div>
            <div className="w-full flex flex-col gap-3 ">
              <label htmlFor="rpassword" className="text-gray-300">
                Re-enter Password
              </label>
              <input
                id="rpassword"
                className="max-w-full border my-1 px-3 py-2 text-gray-300"
                type="password"
                placeholder="Re-enter new password"
                required
              />
            </div>
            <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
              Create new password
            </button>
          </form>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  // code
})(Profile);
