import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import IndividualOrderItems from "./common/IndividualOrderItems";
import { useLocation } from "react-router-dom";
import BasicDocument from "./common/OrderPDF";
const Bill = (props) => {
  var location = useLocation();
  var data = location.state;

  const navigate = useNavigate();

  if (props.loggedIn) {
    // code
    return (
      <div className="max-w-[90vw] md:max-w-[95vw] mx-auto p-5 flex flex-col">
        <BasicDocument
          order_id={data.order_id}
          date={data.date}
          name={data.customer_name}
          number={data.customer_contact}
          items={data.products_data}
          total={data.bill_amount}
          address={data.customer_address}
        />
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  // code
})(Bill);