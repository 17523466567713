import Navbar from "./common/Navbar";
import { connect } from "react-redux";

import {
  editCatalogueNameTextfieldValueChanged,
  editCatalogueName,
} from "../../actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const EditCatalogue = (props) => {
  const location = useLocation()
  var data = location.state;
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  if(props.loggedIn) {
    // code
    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="mt-8 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">
              Edit Catalogue
            </h1>
            <form
              className="flex flex-col gap-3 items-center justify-between max-w-md mx-auto"
              encType="multipart/form-data"
              onSubmit={(event) => {
                event.preventDefault();
                props.editCatalogueName(props.edit_catalogue_name, data, files);
                navigate("/admin/catalogue")
              }}
            >
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="catalogue" className="text-gray-300">
                  Catalogue Name
                </label>
                <input
                  id="catalogue"
                  className="w-full border my-1 px-3 py-2 text-gray-300"
                  type="text"
                  placeholder="Catalogue Name"
                  onChange={(e) => {
                    props.editCatalogueNameTextfieldValueChanged(e.target.value)
                  }}
                />
              </div>
              <div className="flex flex-col gap-3 max-w-full w-full">
                <label htmlFor="address" className="text-gray-300">
                  Catalogue Image
                </label>
                <section className="w-full border my-1 px-3 py-2 ">
                  <div>
                    <input type={"file"} onChange={(event) => {
                      setFiles(event.target.files[0])
                    }} name="catalogue_image" />
                  </div>
                </section>
              </div>
  
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Submit Catalogue
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    edit_catalogue_name: state.inventory.edit_catalogue_name,
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  editCatalogueNameTextfieldValueChanged,
  editCatalogueName,
})(EditCatalogue);
