import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Navbar from "./common/Navbar";
import {
  addProductsNameTextfieldValueChanged,
  addProductsPriceTextfieldValueChanged,
  addProductsQuantityTextfieldValueChanged,
  addProductsCategoryDropdownValueChanged,
  addProductsImageValueChanged,
  addProducts,
  addProductsCatalogueDropdownValueChanged,
  addProductsSubCategoryDropdownValueChanged,
  addProductsThresholdTextfieldValueChanged,
  editProducts
} from "../../actions";

const EditInventory = (props) => {

  var location = useLocation();
  var data = location.state;
  useEffect(() =>{
    props.addProductsCatalogueDropdownValueChanged(data.catalogue_id);
    props.addProductsCategoryDropdownValueChanged(data.category_id);
    props.addProductsSubCategoryDropdownValueChanged(data.sub_category_id);
  }, [])

  const [files, setFiles] = useState([]);

  const handleCatalogueChange = () => {
    var filterredData = props.category_data.filter((item) => item.catalogue_id.toString() === props.add_product_catalogue.toString())
    if (props.add_product_catalogue > 0) {
      return (
        <div className="flex flex-col gap-3 w-full">
          <label htmlFor="category" className="text-gray-300">
            Product Category
          </label>
          <select
            id="category"
            value={props.add_product_category}
            onChange={(ev) => {
              props.addProductsCategoryDropdownValueChanged(
                ev.target.value
              )
            }}
            className="max-w-full border border-gray-300 text-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option  value={0}>Select Category</option>
            {filterredData.map((item) => {
              if (item.category_id === data.category_id) {
                return (
                  <option value={item.category_id} selected>{item.category_name}</option>
                )
              } else {
                return (
                  <option value={item.category_id}>{item.category_name}</option>
                )
              }
            })}
          </select>
        </div>
      )
    } else {
      return (
        <div className="flex flex-col gap-3 w-full">
          <label htmlFor="category" className="text-gray-300">
            Product Category
          </label>
          <select
            id="category"
            value={props.add_product_category}
            onChange={(ev) =>
              props.addProductsCategoryDropdownValueChanged(
                ev.target.value
              )
            }
            className="max-w-full border border-gray-300 text-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          >
            <option  value={0}>Select Category</option>
            {filterredData.map((item) => {
              return (
                <option value={item.category_id}>{item.category_name}</option>
              )
            })}
          </select>
        </div>
      )
    }
  }

  const handleCategoryChange = () => {
    var filterred_data = props.sub_category_data.filter((item) => item.category_id.toString() === props.add_product_category.toString())
    if (props.add_product_category > 0) {
      return (
        <div className="flex flex-col gap-3 w-full">
          <label htmlFor="subcategory" className="text-gray-300">
            Product Sub Category
          </label>
          <select
            id="subcategory"
            value={props.product_sub_category}
            onChange={(ev) => {
              props.addProductsSubCategoryDropdownValueChanged(
                ev.target.value
              );
            }}
            className="max-w-full border border-gray-300 text-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option  value={0}>Select Sub Category</option>
            {filterred_data.map((item) => {
              if(item.sub_category_id === data.sub_category_id) {
                return (
                  <option value={item.sub_category_id} selected>{item.sub_category_name}</option>
                )
              } else {
                return (
                  <option value={item.sub_category_id}>{item.sub_category_name}</option>
                )
              }
            })}
          </select>
        </div>
      )
    } else {
      return (
        <div className="flex flex-col gap-3 w-full">
          <label htmlFor="subcategory" className="text-gray-300">
            Product Sub Category
          </label>
          <select
            id="subcategory"
            value={props.product_sub_category}
            onChange={(ev) =>
              props.addProductsSubCategoryDropdownValueChanged(
                ev.target.value
              )
            }
            className="max-w-full border border-gray-300 text-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          >
            <option  value={0}>Select Sub Category</option>
            {filterred_data.map((item) => {
              return (
                <option value={item.sub_category_id}>{item.sub_category_name}</option>
              )
            })}
          </select>
        </div>
      )
    }
  }

  const navigate = useNavigate();

  if(props.loggedIn) {
    // code
    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="mt-8 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">
              Edit Product
            </h1>
            <form
              className="flex flex-col gap-3 items-center justify-between max-w-md mx-auto"
              onSubmit={(event) => {
                event.preventDefault();
                props.editProducts(props.product_name, props.product_price, props.product_quantity, props.add_product_catalogue, props.add_product_category, props.product_sub_category, files, props.product_threshold, data.product_id, data)
                navigate("/admin/inventory");
              }}
            >
              <div className="flex gap-6 max-w-full w-full">
                <div className="flex flex-col gap-3 w-full">
                  <label htmlFor="number" className="text-gray-300">
                    Product Name
                  </label>
                  <input
                    id="name"
                    className="w-full border my-1 px-3 py-2 text-gray-300"
                    type="text"
                    placeholder={data.product_number}
                    value={props.product_name}
                    onChange={(ev) =>
                      props.addProductsNameTextfieldValueChanged(ev.target.value)
                    }
                    
                  />
                </div>
                  <div className="flex flex-col gap-3 w-full">
                    <label htmlFor="price" className="text-gray-300">
                      Threshold
                    </label>
                    <input
                      id="threshold"
                      className="w-full border my-1 px-3 py-2 text-gray-300"
                      type="number"
                      placeholder={data.threshold}
                      value={props.product_threshold}
                      onChange={(ev) =>
                        props.addProductsThresholdTextfieldValueChanged(ev.target.value)
                      }
                      
                    />
                  </div>
                  </div>
              <div className="flex gap-6 max-w-full w-full">
                <div className="flex flex-col gap-3 w-full">
                  <label htmlFor="quantity" className="text-gray-300">
                    {" "}
                    Quantity
                  </label>
                  <input
                    id="quantity"
                    className="w-full border my-1 px-3 py-2 text-gray-300"
                    type="number"
                    placeholder={data.quantity}
                    value={props.product_quantity}
                    onChange={(ev) =>
                      props.addProductsQuantityTextfieldValueChanged(
                        ev.target.value
                      )
                    }
                    
                  />
                </div>
                <div className="flex flex-col gap-3 w-full">
                  <label htmlFor="price" className="text-gray-300">
                    Price
                  </label>
                  <input
                    id="price"
                    className="w-full border my-1 px-3 py-2 text-gray-300"
                    type="number"
                    placeholder={data.price}
                    value={props.product_price}
                    onChange={(ev) =>
                      props.addProductsPriceTextfieldValueChanged(ev.target.value)
                    }
                    
                  />
                </div>
              </div>
  
              <div className="flex gap-6 max-w-full w-full">
                <div className="flex flex-col gap-3 w-full">
                  <label htmlFor="catalogue" className="text-gray-300">
                    Product Catalogue
                  </label>
                  <select
                    id="catalogue"
                    value={props.add_product_catalogue}
                    onChange={(ev) => {
                      props.addProductsCatalogueDropdownValueChanged(
                        ev.target.value
                      )
                      // handleCatalogueChange(ev.target.value);
                    }}
                    className="max-w-full border border-gray-300 text-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="0">Select Catalogue</option>
                    {props.catalogue_data.map((item) => {
                      if(parseInt(item.catalogue_id) === parseInt(data.catalogue_id)) {
                        return (
                          <option value={item.catalogue_id} selected>{item.catalogue_name}</option>
                        )
                      } else {
                        return (
                          <option value={item.catalogue_id}>{item.catalogue_name}</option>
                        )
                      }
                    })}
                  </select>
                </div>
  
                {handleCatalogueChange()}
              </div>
              <div className="flex gap-6 max-w-full w-full">
                {handleCategoryChange()}
              </div>
              <div className="flex flex-col gap-3 max-w-full w-full">
                <label htmlFor="address" className="text-gray-300">
                  Product Image
                </label>
                <section className="w-full border my-1 px-3 py-2 ">
                  <div>
                    <input type={"file"} onChange={(event) => {
                      setFiles(event.target.files[0])
                    }} name="product_image" />
                  </div>
                </section>
              </div>
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Edit product
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    product_name: state.inventory.add_product_name,
    product_quantity: state.inventory.add_product_quantity,
    product_price: state.inventory.add_product_price,
    product_sub_category: state.inventory.add_product_sub_category,
    product_image: state.inventory.add_product_image,
    sub_category_data: state.inventory.sub_category_data,
    catalogue_data: state.inventory.catalogue_data,
    category_data: state.inventory.category_data,
    add_product_catalogue: state.inventory.add_product_catalogue,
    add_product_category: state.inventory.add_product_category,
    product_threshold: state.inventory.add_product_threshold,
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  addProductsNameTextfieldValueChanged,
  addProductsPriceTextfieldValueChanged,
  addProductsQuantityTextfieldValueChanged,
  addProductsCategoryDropdownValueChanged,
  addProductsImageValueChanged,
  addProducts,
  addProductsCatalogueDropdownValueChanged,
  addProductsSubCategoryDropdownValueChanged,
  addProductsThresholdTextfieldValueChanged,
  editProducts,
})(EditInventory);
