import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "./common/Sidebar";
import CartCard from "./common/CartCard";
import { connect } from "react-redux";
import {
  setCart,
  setOrderDetails,
  ecommerceBillTotalValueChanged,
  ecommerceTotalItemsValueChanged,
} from "../../actions";
import Navbar from "./common/Navbar";
import { StethoscopeIcon } from "lucide-react";

const Cart = (props) => {
  const navigate = useNavigate();
  const [toastopen, settoastopen] = useState(false);
  useEffect(() => {
    var total = 0;
    var items = 0;
    props.cart.map((item) => {
      props.products_data.forEach((product) => {
        if (item.product_id.toString() === product.product_id.toString()) {
          if (item.quantity > 0) {
            items = items + 1;
            total = total + item.quantity * product.price;
          }
        }
      });
    });
    props.ecommerceBillTotalValueChanged(total);
    props.ecommerceTotalItemsValueChanged(items);
  }, [props.bill_total]);

  if (props.total_items > 0) {
    return (
      <div className="relative min-h-[100vh] max-w-[90vw] md:max-w-[95vw] mx-auto">
        <Navbar />
        <h1 className="text-[1.5rem] text-semibold my-2 text-gray-400">
          My Cart
        </h1>
        <div className="flex flex-col-reverse gap-10">
          <div className=" grid grid-cols-1 md:grid-cols-3 md:gap-4 text-gray-400">
            {props.cart &&
              props.products_data &&
              props.cart.map((item) => {
                if (item.quantity > 0) {
                  var productVal = {};
                  props.products_data.forEach((product) => {
                    if (
                      item.product_id.toString() ===
                      product.product_id.toString()
                    ) {
                      productVal = { ...product };
                    }
                  });

                  return (
                    <div className="mb-8">
                      <CartCard
                        counter={item.quantity}
                        title={productVal.product_number}
                        img={productVal.product_image}
                        price={productVal.price}
                        category={productVal.category_data.category_name}
                        subcategory={
                          productVal.sub_category_data.sub_category_name
                        }
                        plusButtonOnClick={() => {
                          props.setCart(
                            props.cart,
                            item.product_id,
                            1,
                            props.products_data
                          );
                        }}
                        minusButtonOnClick={() => {
                          props.setCart(
                            props.cart,
                            item.product_id,
                            -1,
                            props.products_data
                          );
                        }}
                        removeButtonOnClick={() => {
                          props.setCart(
                            props.cart,
                            item.product_id,
                            -item.quantity,
                            props.products_data
                          );
                        }}
                      />
                    </div>
                  );
                }
              })}
          </div>
          <Sidebar
            totalVal={props.bill_total}
            numberOfItems={props.total_items}
            orderButtonOnClick={() => {
              props.setOrderDetails(
                props.cart,
                props.customer_id,
                props.bill_total,
                props.products_data
              );
              alert("Order placed successfully");
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-screen flex-col">
        <Navbar />
        <div className="flex h-[80%] max-w-md mx-auto items-center justify-center">
          <h1 className="text-3xl">Your currently have nothing in your cart</h1>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    cart: state.ecommerce.cart,
    products_data: state.inventory.products_data,
    customer_id: state.ecommerce.customer_id,
    bill_total: state.ecommerce.bill_total,
    total_items: state.ecommerce.total_items,
  };
};

export default connect(mapStateToProps, {
  setCart,
  setOrderDetails,
  ecommerceBillTotalValueChanged,
  ecommerceTotalItemsValueChanged,
})(Cart);
