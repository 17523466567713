import Navbar from "./common/Navbar";
import CatalogueCard from "./common/CatalogueCard";
import { useNavigate } from "react-router-dom";
import { ListPlus } from "lucide-react";
import { connect } from "react-redux";
import {
  deleteCatalogues,
  getCatalogues,
} from "../../actions";
import { useEffect } from "react";

const Catalogue = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.getCatalogues();
  }, [props.deleteCatalogues])

  if(props.loggedIn) {
    // code
    return (
      <div className="pt-5">
        <Navbar />
        <div className="flex justify-between">
          <h1 className="text-[1.5rem] md:text-[2rem] font-bold leading-none text-white py-[2rem] ">
            Catalogues
          </h1>
          <button
            className="flex py-[2rem] gap-2 text-gray-300"
            onClick={() => navigate("/admin/addcatalogue")}
          >
            <ListPlus />
            <h1 className="text-[1rem] md:text-[1.4rem] font-medium leading-none ">
              Add
            </h1>
          </button>
        </div>
        <div className="Catalogue grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          {props.catalogue_data.map((item) => {
            return (
              <CatalogueCard
                title={item.catalogue_name}
                catalogue_data={item}
                image={item.catalogue_image}
                deleteButtonOnPress={(event) => {
                  props.deleteCatalogues(item.catalogue_id,item.catalogue_image);
                  event.stopPropagation();
                  window.location.refresh(false);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    catalogue_data: state.inventory.catalogue_data,
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  deleteCatalogues,
  getCatalogues,
})(Catalogue);
