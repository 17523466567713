import {
  ADD_CUSTOMERS_ADDRESS_TEXTFIELD_VALUE_CHANGED,
  ADD_CUSTOMERS_CONTACT_TEXTFIELD_VALUE_CHANGED,
  ADD_CUSTOMERS_EMAIL_TEXTFIELD_VALUE_CHANGED,
  ADD_CUSTOMERS_NAME_TEXTFIELD_VALUE_CHANGED,
  CREATE_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED,
  CREATE_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED,
  CREATE_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED,
  CREATE_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED,
  CUSTOMERS_SEARCH_TEXTFIELD_VALUE_CHANGED,
  SET_CUSTOMERS_DATA,
  SET_CUSTOMER_DETAILS,
} from "../actions";

var INITIAL_STATE = {
  customer_search: "",

  customer_name: "",
  customer_email: "",
  customer_contact: "",
  customer_address: "",

  addCustomerNameEcommerce: "",
  addCustomerContactEcommerce: "",
  addCustomerEmailEcommerce: "",
  addCustomerAddressEcommerce: "",

  customer_details: {},
  customer_data: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMERS_SEARCH_TEXTFIELD_VALUE_CHANGED:
      return { ...state, customer_search: action.payload };
    case ADD_CUSTOMERS_NAME_TEXTFIELD_VALUE_CHANGED:
      return { ...state, customer_name: action.payload };
    case ADD_CUSTOMERS_EMAIL_TEXTFIELD_VALUE_CHANGED:
      return { ...state, customer_email: "" };
    case ADD_CUSTOMERS_CONTACT_TEXTFIELD_VALUE_CHANGED:
      return { ...state, customer_contact: action.payload };
    case ADD_CUSTOMERS_ADDRESS_TEXTFIELD_VALUE_CHANGED:
      return { ...state, customer_address: action.payload };
    case SET_CUSTOMERS_DATA:
      return { ...state, customer_data: action.payload };
    case CREATE_CUSTOMER_NAME_TEXTFIELD_VALUE_CHANGED:
      return { ...state, addCustomerNameEcommerce: action.payload };
    case CREATE_CUSTOMER_EMAIL_TEXTFIELD_VALUE_CHANGED:
      return { ...state, addCustomerEmailEcommerce: "" };
    case CREATE_CUSTOMER_CONTACT_TEXTFIELD_VALUE_CHANGED:
      return { ...state, addCustomerContactEcommerce: action.payload };
    case CREATE_CUSTOMER_ADDRESS_TEXTFIELD_VALUE_CHANGED:
      return { ...state, addCustomerAddressEcommerce: action.payload };
    case SET_CUSTOMER_DETAILS:
      return { ...state, customer_details: action.payload };
    default:
      return state;
  }
};
