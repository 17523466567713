const IndividualOrderItems = (props) => {
  const {
    productNumber,
    productQuantity,
    productPrice,
    productImage,
    productTotal,
  } = props;

  return (
    <div className="max-w-[90vw] md:max-w-[80vw] flex justify-between mx-auto text-white">
      <div className=" mt-2">
        <img src={productImage} height={40} width={50} />
      </div>
      <div className="flex flex-col gap-1 text-sm md:text-md">
        <h3 className="">Product Number</h3>
        <p className="">{productNumber}</p>
      </div>

      <div className="flex flex-col gap-1 text-sm md:text-md">
        <h3 className="">Quantity</h3>
        <p className="">{productQuantity}</p>
      </div>

      <div className="flex flex-col gap-1 text-sm md:text-md">
        <h3 className="">Price</h3>
        <p className="">Rs.{productPrice}</p>
      </div>

      <div className="flex flex-col gap-1 text-sm md:text-md mb-5">
        <h3 className="">Total</h3>
        <p className="">Rs.{productTotal}</p>
      </div>
    </div>
  );
};

export default IndividualOrderItems;
