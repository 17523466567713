import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  addCustomersNameTextfieldValueChanged,
  addCustomersEmailTextfieldValueChanged,
  addCustomersContactTextfieldValueChanged,
  addCustomersAddressTextfieldValueChanged,
  editCustomers,
} from "../../actions";

import Navbar from "./common/Navbar";

const EditCustomer = (props) => {
  // const [password, setpassword] = useState("");
  // const [cpassword, setcpassword] = useState("");

  const navigate = useNavigate();
  var location = useLocation();
  var data = location.state;

  if(props.loggedIn) {
    // code
    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="mt-6 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">
              Edit Customer Details
            </h1>
            <form
              className="flex flex-col gap-3 items-center justify-between max-w-md mx-auto"
              onSubmit={(event) => {
                event.preventDefault();
                props.editCustomers(props.customer_name, props.customer_contact, props.customer_email, props.customer_address, data);
                navigate("/admin/customers");
              }}
            >
              <div className="w-full flex flex-col gap-3 ">
                <label htmlFor="name" className="text-gray-300">
                  {" "}
                  Full name
                </label>
                <input
                  id="name"
                  className="max-w-full border my-1 px-3 py-2 text-gray-300"
                  type="text"
                  placeholder={data.name}
                  value={props.customer_name}
                  onChange={(ev) =>
                    props.addCustomersNameTextfieldValueChanged(ev.target.value)
                  }
                  required
                />
              </div>
              <div className="flex gap-6">
                <div className="flex flex-col gap-3 ">
                  <label htmlFor="email" className="text-gray-300">
                    Email
                  </label>
                  <input
                    id="email"
                    className="w-full border my-1 px-3 py-2 text-gray-300"
                    type="email"
                    placeholder={data.email}
                    value={props.customer_email}
                    onChange={(ev) =>
                      props.addCustomersEmailTextfieldValueChanged(
                        ev.target.value
                      )
                    }
                    required
                  />
                </div>
                <div className="flex flex-col gap-3 ">
                  <label htmlFor="phone" className="text-gray-300">
                    Phone number
                  </label>
                  <input
                    id="phone"
                    className="w-full border my-1 px-3 py-2 text-gray-300"
                    type="number"
                    placeholder={data.contact_number}
                    value={props.customer_contact}
                    onChange={(ev) =>
                      props.addCustomersContactTextfieldValueChanged(
                        ev.target.value
                      )
                    }
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-3 justify-left">
                <label htmlFor="address" className="text-gray-300">
                  Address
                </label>
                <textarea
                  rows={3}
                  cols={55}
                  id="address"
                  className="w-full border my-1 px-3 py-2 text-gray-300"
                  type="text"
                  placeholder={data.address}
                  value={props.customer_address}
                  onChange={(ev) =>
                    props.addCustomersAddressTextfieldValueChanged(
                      ev.target.value
                    )
                  }
                  required
                />
              </div>
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Edit Customer details
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    customer_name: state.customer.customer_name,
    customer_email: state.customer.customer_email,
    customer_contact: state.customer.customer_contact,
    customer_address: state.customer.customer_address,
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  addCustomersNameTextfieldValueChanged,
  addCustomersEmailTextfieldValueChanged,
  addCustomersContactTextfieldValueChanged,
  addCustomersAddressTextfieldValueChanged,
  editCustomers,
})(EditCustomer);
