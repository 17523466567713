import { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isopen, setisopen] = useState(false);
  return (
    <nav className="relative flex items-center justify-between flex-wrap pt-2">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <span className="font-semibold text-xl tracking-tight">
          Kamani Arts
        </span>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => setisopen((prev) => !prev)}
          className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
        {isopen && (
          <div className="absolute w-full block flex-grow left-0 z-10">
            <div className="text-md lg:flex-grow lg:pl-[2rem]">
              <Link
                to="/admin/home"
                className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
              >
                Home
              </Link>
              <Link
                to="/admin/orders"
                className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
              >
                Orders
              </Link>
              <Link
                to="/admin/inventory"
                className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
              >
                Inventory
              </Link>
              <Link
                to="/admin/catalogue"
                className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
              >
                Catalogue
              </Link>
              <Link
                to="/admin/customers"
                className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white"
              >
                Customers
              </Link>
              {/* <Link
                to="/profile"
                className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white"
              >
                Profile
              </Link> */}
            </div>
            <div>
              <Link
                to="/admin/signout"
                className="inline-block text-md px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
              >
                Sign Out
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className=" hidden w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div className="text-md lg:flex-grow lg:pl-[2rem]">
          <Link
            to="/admin/home"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
          >
            Home
          </Link>
          <Link
            to="/admin/orders"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
          >
            Orders
          </Link>
          <Link
            to="/admin/inventory"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
          >
            Inventory
          </Link>
          <Link
            to="/admin/catalogue"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
          >
            Catalogue
          </Link>
          <Link
            to="/admin/customers"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
          >
            Customers
          </Link>
          {/* <Link
            to="/Profile"
            className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white"
          >
            Profile
          </Link> */}
        </div>
        <div>
          <Link
            to="/admin/signout"
            className="inline-block text-md px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
          >
            Sign Out
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
