import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Plus, MinusCircle } from "lucide-react";
import Navbar from "./common/Navbar";
import {
  addOrdersCustomerAddressTextfieldValueChanged,
  addOrdersCustomerContactTextfieldValueChanged,
  addOrdersCustomerEmailTextfieldValueChanged,
  addOrdersInputFieldsValueChanged,
  addOrdersCustomerNameTextfieldValueChanged,
  addOrdersCatalogueDropdownValueChanged,
  editOrder,
} from "../../actions";

const EditOrder = (props) => {

  var location = useLocation();
  var legacy_data = location.state;
  const [inputFields, setInputFields] = useState(legacy_data.products);
  console.log(legacy_data)

  const navigate = useNavigate();

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = { name: "", quantity: "", price: "" };
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  if(props.loggedIn) {
    // code
    return (
      <div className=" h-screen pt-5">
        <Navbar />
        <div className="mt-5 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">Edit Order</h1>
            <div className="w-full items-start grid grid-cols-2 lg:grid-cols-6 gap-6 md:gap-5 p-4 border-b-[2px]">
              <div className="flex flex-col gap-1">
                <h3 className="text-gray-300">Order ID</h3>
                <p className="text-gray-300">{legacy_data.orderid}</p>
              </div>
              <div className="flex flex-col gap-1">
                <h3 className="text-gray-300">Name </h3>
                <p className="text-gray-300">{legacy_data.customer_name}</p>
              </div>
              <div className="flex flex-col gap-1">
                <h3 className="text-gray-300">Email </h3>
                <p className="text-gray-300">{legacy_data.customer_email}</p>
              </div>
              <div className="flex flex-col gap-1">
                <h3 className="text-gray-300">Phone Number</h3>
                <p className="text-gray-300">{legacy_data.customer_contact}</p>
              </div>
              <div className="flex flex-col gap-1">
                <h3 className="text-gray-300">Address </h3>
                <p className="text-gray-300">{legacy_data.customer_address}</p>
              </div>
              <div className="flex flex-col gap-1">
                <h3 className="text-gray-300">Order Date</h3>
                <p className="text-gray-300">{legacy_data.date}</p>
              </div>
            </div>
            <form
              className=" flex flex-col flex-1 gap-3 items-center max-w-lg mx-auto mt-10"
              onSubmit={(event) => {
                event.preventDefault();
                props.editOrder(inputFields, legacy_data);
                navigate("/admin/orders");
              }}
            >
  
              {inputFields.map((input, index) => {
                return (
                  <div className="flex gap-2 md:gap-6" key={index}>
                    <div className="flex flex-col gap-3 ">
                      <label htmlFor="product" className="text-gray-300">
                        Number
                      </label>
                      <input
                        id="name"
                        name="name"
                        className="w-full border my-1 px-1 md:px-3 py-2 "
                        type="text"
                        placeholder={input.product_number}
                        value={input.product_number}
                        onChange={(event) => handleFormChange(index, event)}
                        disabled
                      />
                    </div>
                    <div className="flex flex-col gap-3 ">
                      <label htmlFor="quantity" className="text-gray-300">
                        Quantity{" "}
                      </label>
                      <input
                        id="quantity"
                        name="quantity"
                        className="w-full border my-1 px-1 md:px-3 py-2 "
                        type="number"
                        placeholder={input.quantity}
                        value={input.quantity}
                        onChange={(event) => handleFormChange(index, event)}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-3 ">
                      <label htmlFor="price" className="text-gray-300">
                        Price{" "}
                      </label>
                      <input
                        id="Price"
                        name="price"
                        className="w-full border my-1 px-3 py-2 "
                        type="number"
                        placeholder={input.price}
                        value={input.price}
                        onChange={(event) => handleFormChange(index, event)}
                        disabled
                      />
                    </div>
  
                    {/* <button
                      className="flex self-center py text-gray-500 mt-8"
                      onClick={() => removeFields(index)}
                    >
                      <MinusCircle />
                    </button> */}
                  </div>
                );
              })}
              {/* <button
                className=" flex self-end text-gray-500"
                onClick={addFields}
              >
                <Plus />
                Add new product
              </button> */}
  
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Submit Order
              </button>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    customer_name: state.orders.customer_name,
    customer_contact: state.orders.customer_contact,
    customer_email: state.orders.customer_email,
    customer_address: state.orders.customer_address,
    order_notes: state.orders.order_notes,
    inputFields: state.orders.inputFields,
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  addOrdersInputFieldsValueChanged,
  addOrdersCustomerAddressTextfieldValueChanged,
  addOrdersCustomerContactTextfieldValueChanged,
  addOrdersCustomerEmailTextfieldValueChanged,
  addOrdersCatalogueDropdownValueChanged,
  addOrdersCustomerNameTextfieldValueChanged,
  editOrder,
})(EditOrder);
