import { Link, useNavigate } from "react-router-dom";
import { UserCircle } from "lucide-react";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <nav className="max-w-[90vw] md:max-w-[95vw] mx-auto flex items-center justify-between pt-5 mb-5">
      <button
        className="font-semibold text-lg text-gray-400 md:text-xl tracking-tight"
        onClick={() => navigate("/")}
      >
        Gurukripa Trading - Kamani Arts{" "}
      </button>

      {/* <div className="inline-block flex items-center w-auto"> */}
      <div className="text-md flex items-center md:pl-[2rem]">
        <button
          onClick={() => navigate("/customer/cart")}
          className=" mt-1 flex gap-2 inline-block items-center mt-0 md:mr-4 "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-shopping-cart pb-1"
          >
            <circle cx="8" cy="21" r="1" />
            <circle cx="19" cy="21" r="1" />
            <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12" />
          </svg>
          Cart
        </button>
        {/* <button className="flex items-end px-3 py-2">
            <UserCircle />
          </button> */}
      </div>
      {/* </div> */}
    </nav>
  );
};

export default Navbar;
