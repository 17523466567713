import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ListPlus } from "lucide-react";
import {
  customerSearchTextfieldValueChanged,
  deleteCustomers,
  getCustomerDetails,
} from "../../actions";
import { useNavigate } from "react-router-dom";
import CustomerCard from "./common/CustomerCard";
import Navbar from "./common/Navbar";

const Customers = (props) => {

  const [startDate, setStartDate] = useState(new Date());
  const [search, setsearch] = useState("");
  const [isopen, setisopen] = useState(false);
  const navigate = useNavigate();
  const goto = () => {
    navigate("/admin/addcustomers");
  };

  var filtereddata = [];
  if (props.customer_search) {
    const filtereddata = props.customer_data.filter((item) =>
      item.customer_name.toString().toLowerCase().includes(search.toLowerCase())
    );
  } else {
    filtereddata = filtereddata.concat(props.customer_data);
  }

  useEffect(() => {
    props.getCustomerDetails()
  }, []);

  if(props.loggedIn) {
    // code
    return (
      <div className="pt-5">
        <Navbar />
        <div className="flex justify-between">
          <h1 className="text-[1.5rem] md:text-[2rem] font-bold leading-none text-white py-[2rem] ">
            Customers
          </h1>
  
          <button className="flex py-[2rem] gap-2 text-gray-300" onClick={goto}>
            <ListPlus />
            <h1 className="text-[1rem] md:text-[1.4rem] font-medium leading-none ">
              Add
            </h1>
          </button>
        </div>
        <div className="max-w-full w-full flex items-center gap-4">
          <form className="w-full flex items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <input
                type="text"
                value={props.customer_search}
                onChange={(e) =>
                  props.customerSearchTextfieldValueChanged(e.target.value)
                }
                id="simple-search"
                className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Customers"
                required
              />
            </div>
            <button
              type="submit"
              className="p-2.5 ml-2 text-sm font-medium text-white rounded-lg border border-white focus:outline-none"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </form>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 mt-3">
          {filtereddata &&
            filtereddata.map((item) => {
              return (
                <CustomerCard
                  customerId={item.id}
                  customerName={item.name}
                  customerAddress={item.address}
                  customerContact={item.contact_number}
                  customerEmail={item.email}
                  customerDate={item.creation_date}
                  customerDetails={item}
                  deleteButtonOnPress={(event) => {
                    props.deleteCustomers(item.customer_id);
                    event.stopPropagation();
                    window.location.reload();
                  }}
                />
              );
            })}
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    customer_data: state.customer.customer_data,
    customer_search: state.customer.customer_search,
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  customerSearchTextfieldValueChanged,
  deleteCustomers,
  getCustomerDetails,
})(Customers);
