const TableListItem = (props) => {

    const {
        customerName,
        customerEmail,
        orderAmount,
        inventory,
    } = props;

    if (inventory) {
        return (
            <li className="py-3 sm:py-4">
                <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-400 truncate dark:text-white">
                    {customerName}
                    </p>
                    <p className="text-sm text-gray-400 truncate dark:text-gray-400">
                    {customerEmail}
                    </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-400 dark:text-white">
                    Rs. {orderAmount}
                </div>
                </div>
            </li>
        )
    } else {
        return (
            <li className="py-3 sm:py-4">
                <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-400 truncate dark:text-white">
                    {customerName}
                    </p>
                    <p className="text-sm text-gray-400 truncate dark:text-gray-400">
                    {customerEmail}
                    </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-400 dark:text-white">
                    {orderAmount}
                </div>
                </div>
            </li>
        )
    }
}

export default TableListItem;