import { Mail, Phone } from "lucide-react";

const Footer = () => {
  return (
    <footer className="rounded-lg shadow my-4">
      <div className="w-full max-w-screen-xl mx-auto p-4 ">
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="self-start text-2xl font-semibold text-gray-400">
            Gurukripa Trading - Kamani Arts
          </span>

          <section className="flex flex-col leading-1.3 max-w-md flex-wrap items-start mb-6 text-sm font-medium text-gray-500 sm:mb-0">
            <h3 className="text-2xl text-semibold mb-2">Get in touch</h3>
            <p>
              <span className="text-bold text-red-500">Main Address :</span>{" "}
              GuruKripa trading co. 356 raviwar peth, Bhori ali. Pune. 411042
            </p>
            <p className="flex gap-2 mt-1">
              <Mail className="h-4 self-center" /> info@gurukripatrading.com
            </p>
            <p className="flex gap-2 mb-2">
              <Phone className="h-4 self-center" />
              +91 7588846211 | +91 9823168985
            </p>
            <p>
              <span className="text-bold text-red-500">Site Address : </span>
              Ekbote Colony, Ghorpade Peth, Swargate, Pune, Maharashtra 411042
            </p>
            <p className="flex gap-2 mt-1">
              {" "}
              <Phone className="h-4 self-center" />
              +91 7588846211 | +91 9823168985
            </p>
          </section>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-2" />
        <span className="block text-sm text-gray-500 sm:text-center ">
          © 2023 GuruKripa Trading. All Rights Reserved | Designed by{" "}
          <a className="text-white text-bold" href="https://quixilinx.com">
            Quixilinx
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
