import {
    PASSOWRD_TEXTFIELD_VALUE_CHANGED,
    SET_LOGIN_VARIABLE,
    USERNAME_TEXTFIELD_VALUE_CHANGED,
} from "../actions";

var INITIAL_STATE = {
    username: "",
    password: "",
    token: "",

    loggedIn: true,
};

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case USERNAME_TEXTFIELD_VALUE_CHANGED:
            return {...state, username: action.payload};
        case PASSOWRD_TEXTFIELD_VALUE_CHANGED:
            return {...state, password: action.payload};
        case SET_LOGIN_VARIABLE:
            return {...state, loggedIn: action.payload};
        default:
            return state;
    }
}