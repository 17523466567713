import { ECOMMERCE_CART_COUNTER_VALUE_CHANGED, ECOMMERCE_CART_PRODUCTS_VALUE_CHANGED, ECOMMERCE_CART_VALUE_CHANGED, ECOMMERCE_CUSTOMER_ID_VALUE_CHANGED, ECOMMERCE_INDEX_VALUE_CHANGED, ECOMMERCE_PRODUCT_SEARCH_TEXTFIELD_VALUE_CHANGED, ECOMMERCE_REMOVE_INDEX_VALUE_CHANGED, ECOMMERCE_SET_BILL_TOTAL, ECOMMERCE_TOTAL_ITEMS_VALUE_CHANGED, TOGGLE_ECOMMERCE_LOADER } from "../actions";

var INITIAL_STATE = {
    ecommerce_product_search: "",

    cart_counter: false,
    cart: [],
    customer_id: 0,

    productLoaderVisible: true,

    index: null,
    removeindex: null,
    bill_total: 0,
    total_items: 0,
};

export default (state=INITIAL_STATE, action) => {
    
    switch(action.type) {
        case ECOMMERCE_PRODUCT_SEARCH_TEXTFIELD_VALUE_CHANGED:
            return {...state, ecommerce_product_search: action.payload};
        case ECOMMERCE_CART_COUNTER_VALUE_CHANGED:
            return {...state, cart_counter: action.payload};
        case ECOMMERCE_CART_VALUE_CHANGED:
            return {...state, cart: action.payload};
        case ECOMMERCE_CUSTOMER_ID_VALUE_CHANGED:
            return {...state, customer_id: action.payload};
        case TOGGLE_ECOMMERCE_LOADER:
            return {...state, productLoaderVisible: action.payload};
        case ECOMMERCE_INDEX_VALUE_CHANGED:
            return {...state, index: action.payload};
        case ECOMMERCE_REMOVE_INDEX_VALUE_CHANGED:
            return {...state, removeindex: action.payload};
        case ECOMMERCE_SET_BILL_TOTAL:
            return {...state, bill_total: action.payload};
        case ECOMMERCE_TOTAL_ITEMS_VALUE_CHANGED:
            return {...state, total_items: action.payload};
        default:
            return state;
    }
}