const Hero = () => {
  return (
    <div className=" w-full">
      <img
        className="max-w-full w-full object-cover max-h-[60vh]"
        src="/login_home_image.jpeg"
      />
    </div>
  );
};

export default Hero;
