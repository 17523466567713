import Products from "../components/customer/common/Products";
import Home from "../components/customer/Home";
import Cart from "../components/customer/Cart";
import Product from "../components/customer/Product";
import Search from "../components/customer/Search";
import Catalogue from "../components/customer/Catalogue";
import Category from "../components/customer/Category";
import SubCategory from "../components/customer/SubCategory";

import { Routes, Route } from "react-router-dom";

const EcommerceRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/customer/catalogue" element={<Catalogue />} />
            <Route path="/customer/catalogue/category" element={<Category />} />
            <Route
                path="/customer/catalogue/category/subcategory"
                element={<SubCategory />}
            />
            <Route
                path="/customer/catalogue/category/subcategory/products"
                element={<Products />}
            />
            <Route path="/customer/product" element={<Product />} />
            <Route path="/customer/search" element={<Search />} />
            <Route path="/customer/cart" element={<Cart />} />
        </Routes>
    )
}

export default EcommerceRoutes;