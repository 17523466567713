import {
  Blocks,
  Pencil,
  Trash2,
  MoreHorizontal,
  MoreVertical,
} from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ProductCard = (props) => {
  const {
    itemId,
    itemProductNumber,
    itemDepartment,
    itemQuantity,
    itemPrice,
    itemImage,
    navigationVar,
    itemCategory,
    itemSubCategory,
    item,
    deleteButtonOnPress,
  } = props;

  const handleedit = () => {
    setactionopen((prev) => !prev);
    navigate("/admin/editinventory", { state: item });
  };

  const [isopen, setisopen] = useState(false);
  const [actionopen, setactionopen] = useState(false);
  const [collapse, setcollapse] = useState(false);
  const [modalisopen, setmodalisopen] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <div key={itemId}>
        <div className="parent flex justify-between flex-row-reverse mb-4 text-white">
          <button
            onClick={() => setactionopen((prev) => !prev)}
            id="dropdownDefaultButton"
            className="child relative flex text-white focus:outline-none font-medium rounded-lg text-sm text-center inline-flex items-center"
          >
            <MoreVertical />
          </button>
          <h1 className="child flex text-l text-bold ">
            Product Number : {itemProductNumber}
          </h1>
          {actionopen && (
            <div className="z-5 flex flex-col gap-3 absolute mt-10 rounded-lg shadow">
              <button onClick={handleedit} className="flex gap-2">
                <Pencil />
                Edit
              </button>{" "}
              <button onClick={deleteButtonOnPress} className="flex gap-2">
                <Trash2 />
                Delete
              </button>
            </div>
          )}
        </div>

        <img className=" w-full h-[30vh] object-contain" src={itemImage} />
        <p className="text-xs mt-2 ">Catalogue : {itemDepartment}</p>
        <div className="flex gap-2">
          <p className="py-1 text-sm rounded-lg text-gray-400 text-base ">
            {itemCategory}
          </p>
          <p className="py-1 text-sm rounded-lg text-gray-400 text-base ">
            {itemSubCategory}
          </p>
        </div>
        <div className="flex justify-between my-3">
          <p className="flex gap-2 ">
            <Blocks /> {itemQuantity}
          </p>
          <h3 className="text-xl "> Rs.{itemPrice}</h3>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
