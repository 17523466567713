import { useNavigate } from "react-router-dom";
import {
  usernameTextfieldValueChanged,
  passwordTextfieldValueChanged,
  checkLogin,
} from "../../actions";
import { connect } from "react-redux";

const Login = (props) => {
  const navigate = useNavigate();

  return (
    <div className="flex parent w-full min-h-[100vh]">
      <div className="flex parent flex-col lg:flex-row flex-1">
        <div className="flex child flex-1 flex-col items-center justify-center">
          <img
            src="/login_home_image.jpeg"
            className="md:h-full h-[70%] object-cover w-full"
          />
        </div>
        <div className="flex parent flex-1 flex-col items-center justify-center mb-3 md:mb-0">
          <h1 className="flex child text-4xl text-white text-center mb-4">
            Login
          </h1>
          <form
            className="flex child flex parent flex-col items-center justify-center mt-10 w-full"
            onSubmit={(event) => {
              event.preventDefault();
              props.checkLogin(props.username, props.password, navigate);
            }}
          >
            <div className="flex child flex-col w-full md:w-[40%]">
              <label htmlFor="name" className="text-white">
                {" "}
                Enter Username
              </label>
              <input
                id="name"
                className="border px-3 py-2 mt-2"
                type="text"
                placeholder="Username"
                value={props.username}
                onChange={(event) => {
                  props.usernameTextfieldValueChanged(event.target.value);
                }}
                required
              />
            </div>
            <div className="flex child flex-col mt-7 w-full md:w-[40%]">
              <label htmlFor="password" className="text-white">
                {" "}
                Enter Password
              </label>
              <input
                id="password"
                className="max-w-full border px-3 py-2 mt-2"
                type="password"
                placeholder="Enter password"
                value={props.password}
                onChange={(event) => {
                  props.passwordTextfieldValueChanged(event.target.value);
                }}
                required
              />
            </div>
            <button className="flex child justify-center primary rounded-lg bg-primary px-2 py-3 text-white w-full md:w-[40%] mt-10">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.profile.username,
    password: state.profile.password,
  };
};

export default connect(mapStateToProps, {
  usernameTextfieldValueChanged,
  passwordTextfieldValueChanged,
  checkLogin,
})(Login);
