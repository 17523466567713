import Navbar from "./common/Navbar";
import { useState } from "react";
import { connect } from "react-redux";
import {
  addCategoryNameTextfieldValueChanged,
  addCategoryCatalogueDropdownValueChanged,
  addCategory,
} from "../../actions";
import { useNavigate } from "react-router-dom";

const AddCategory = (props) => {

  const navigate = useNavigate();

  if(props.loggedIn) {

    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="mt-8 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">Add Category</h1>
            <form
              className="flex flex-col gap-3 items-center justify-between max-w-md mx-auto"
              onSubmit={(event) => {
                event.preventDefault();
                props.addCategory(props.add_category_name, props.add_category_catalogue);
                navigate("/admin/catalogue")
              }}
            >
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="category" className="text-gray-300">
                  Category Name
                </label>
                <input
                  id="category"
                  className="w-full border my-1 px-3 py-2 text-gray-300"
                  type="text"
                  value={props.add_category_name}
                  onChange={(e) => {
                    props.addCategoryNameTextfieldValueChanged(e.target.value);
                  }}
                  placeholder="Category Name"
                  required
                />
              </div>
  
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="Category" className="text-gray-300">
                  Catalogue Name
                </label>
                <select
                  id="Catalogue"
                  value={props.add_category_catalogue}
                  onChange={(ev) => {
                    props.addCategoryCatalogueDropdownValueChanged(ev.target.value)
                  }}
                  className="max-w-full border border-gray-300 text-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>Select category </option>
                  {props.catalogue_data.map((item) => {
                    return (
                      <option value={item.catalogue_id}>{item.catalogue_name}</option>
                    )
                  })}
                </select>
              </div>
  
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Add Category
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    add_category_name: state.inventory.add_category_name,
    add_category_catalogue: state.inventory.add_category_catalogue,
    catalogue_data: state.inventory.catalogue_data,
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  addCategoryNameTextfieldValueChanged,
  addCategoryCatalogueDropdownValueChanged,
  addCategory,
})(AddCategory);
