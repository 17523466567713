import Navbar from "./common/Navbar";
import SubCategoryCard from "./common/SubCategoryCard";
import { useLocation, useNavigate } from "react-router-dom";
import { ListPlus } from "lucide-react";

import { connect } from "react-redux";
import {
  deleteSubCategory,
  getSubCategory,
} from "../../actions";
import { useEffect } from "react";

const SubCategory = (props) => {

  var location = useLocation();
  var data = location.state;

  useEffect(() => {
    props.getSubCategory();
  }, [])
  
  var filterred_data = props.sub_category_data.filter((item) => item.category_id.toString() === data.category_id.toString())

  const navigate = useNavigate();

  if(props.loggedIn) {
    // code
    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="flex justify-between">
          <h1 className="text-[1.5rem] md:text-[2rem] font-bold leading-none text-white py-[2rem] ">
            SubCategories
          </h1>
          <button
            className="flex py-[2rem] gap-2 text-gray-300"
            onClick={() => navigate("/admin/addsubcategory")}
          >
            <ListPlus />
            <h1 className="text-[1rem] md:text-[1.4rem] font-medium leading-none ">
              Add
            </h1>
          </button>
        </div>
        <div className="Catalogue grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          {filterred_data.map((item) => {
              return (
                <SubCategoryCard subCategoryName={item.sub_category_name} subCategoryID={item} deleteButtonOnPress={(event) => {
                  props.deleteSubCategory(item.sub_category_id);
                  event.stopPropagation();
                  window.location.reload(false);
                }} />
              );
            })
          }
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    sub_category_data: state.inventory.sub_category_data,
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  deleteSubCategory,
  getSubCategory,
})(SubCategory);
