import Navbar from "./common/Navbar";
import { connect } from "react-redux";
import {
  editCategoryNameTextfieldValueChanged,
  editCategoryName,
} from "../../actions";
import { useLocation, useNavigate } from "react-router-dom";

const EditCategory = (props) => {

  const location = useLocation();
  var data = location.state;
  const navigate = useNavigate();

  if(props.loggedIn) {
    // code
    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="mt-8 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">
              Edit Category
            </h1>
            <form
              className="flex flex-col gap-3 items-center justify-between max-w-md mx-auto"
              onSubmit={(event) => {
                event.preventDefault();
                props.editCategoryName(props.edit_category_name, data);
                navigate("/admin/catalogue");
              }}
            >
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="category" className="text-gray-300">
                  Category Name
                </label>
                <input
                  id="category"
                  className="w-full border my-1 px-3 py-2 text-gray-300"
                  type="text"
                  onChange={(e) => {
                    props.editCategoryNameTextfieldValueChanged(e.target.value);
                  }}
                  value={props.edit_category_name}
                  placeholder="Category Name"
                  required
                />
              </div>
  
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Edit Category
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    edit_category_name: state.inventory.edit_category_name,
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  editCategoryNameTextfieldValueChanged,
  editCategoryName,
})(EditCategory);
