import { Routes, Route } from "react-router-dom";

import axios from "axios";
import AdminRoutes from "./AdminRoutes";
import EcommerceRoutes from "./EcommerceRoutes";
// axios.defaults.baseURL = "http://localhost:3000";
axios.defaults.withCredentials = true;

const MainAppRoutes = () => {
  return (
    <div className="max-w-[90vw] md:max-w-[95vw] mx-auto min-h-screen max-h-auto">
      <Routes>
        {/* <Route path="/" Component={Login} /> */}
        <Route path="*" element={<EcommerceRoutes />} />
        <Route exact path="/admin/*" element={<AdminRoutes />} />
      </Routes>
    </div>
  );
};

export default MainAppRoutes;
