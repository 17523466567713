import Navbar from "./common/Navbar";
import { connect } from "react-redux";
import {
  addSubCategoryNameTextfieldValueChanged,
  addSubCategoryCategoryDropdownValueChanged,
  addSubCategoryCatalogueValueChanged,
  addSubCategory,
} from "../../actions";
import { useNavigate } from "react-router-dom";

const AddSubcategory = (props) => {

  const navigate = useNavigate();

  if(props.loggedIn) {
    // code
    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="mt-8 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">
              Add Sub Category
            </h1>
            <form
              className="flex flex-col gap-3 items-center justify-between max-w-md mx-auto"
              onSubmit={(event) => {
                event.preventDefault();
                props.addSubCategory(props.add_sub_category_name, props.add_sub_category_category, props.add_sub_category_catalogue);
                navigate("/admin/catalogue")
              }}
            >
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="subcategory" className="text-gray-300">
                  Sub Category Name
                </label>
                <input
                  id="subcategory"
                  className="w-full border my-1 px-3 py-2 text-gray-300"
                  type="text"
                  placeholder="Sub Category Name"
                  value={props.add_sub_category_name}
                  onChange={(e) => {
                    props.addSubCategoryNameTextfieldValueChanged(e.target.value)
                  }}
                  required
                />
              </div>
  
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="Category" className="text-gray-300">
                  Category Name
                </label>
                <select
                  id="Catalogue"
                  value={props.add_sub_category_category}
                  onChange={(ev) => {
                    props.addSubCategoryCategoryDropdownValueChanged(ev.target.value)
                    if (ev.target.value > 0) {
                      props.addSubCategoryCatalogueValueChanged(props.category_data.filter((item)=>item.category_id.toString() === ev.target.value)[0].catalogue_id)
                    }
                  }}
                  className="max-w-full border border-gray-300 text-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>Select category </option>
                  {props.category_data.map((item) => {
                    return (
                      <option value={item.category_id}>{item.category_name}</option>
                    )
                  })}
                </select>
              </div>
  
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Add Subcategory
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = state => {
  return {
    add_sub_category_name: state.inventory.add_sub_category_name,
    add_sub_category_category: state.inventory.add_sub_category_category,
    add_sub_category_catalogue: state.inventory.add_sub_category_catalogue,
    category_data: state.inventory.category_data,
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  addSubCategoryNameTextfieldValueChanged,
  addSubCategoryCategoryDropdownValueChanged,
  addSubCategoryCatalogueValueChanged,
  addSubCategory,
})(AddSubcategory);
