const Sidebar = (props) => {

  const {
    totalVal,
    numberOfItems,
    orderButtonOnClick,
  } = props;

  return (
    <div className="border-l-[2px] p-4 flex gap-5 md:gap-10 text-gray-400 flex-1 justify-between">
      <div className="flex flex-[0.2] justify-around">
        <div className="flex-col align-start justify-start mr-10">
          <h1>Total</h1>
          <h1>Rs.{totalVal}</h1>
        </div>
        <div className="flex-col align-start justify-start">
          <h1>Items</h1>
          <h1>{numberOfItems}</h1>
        </div>
      </div>
      <button className="flex py-2 px-4 rounded-lg border justify-self-end" onClick={orderButtonOnClick}>Place Order</button>
    </div>
  );
};

export default Sidebar;
