import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { BaggageClaim } from "lucide-react";
import { useLocation } from "react-router-dom";
import Navbar from "./common/Navbar";
import { connect, useDispatch } from "react-redux";
import {
  ecommerceCartValueChanged,
  setCart,
} from "../../actions";
import { useEffect } from "react";

const Product = (props) => {
  const [counter, setcounter] = useState(1);
  const [buttonName, setButtonName] = useState("Add To Cart");
  // const [cart, setCart] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {cart} = props;
  useEffect(() => {
    // setCart(props.cart);
  }, [dispatch]);

  var location = useLocation();
  var data = location.state;
  return (
    <div>
      <Navbar />
      <div className="min-h-[100vh] flex flex-col md:flex-row md:max-h-[80vh] justify-center gap-5 md:gap-10 md:mt-4 mt-10">
        <div className=" md:max-h-full md:max-w-[50%] w-full rounded-lg">

          <img
            className="h-[40vh] md:h-[80%] w-full object-contain  rounded-[20px]"
            src={data.product_image}
          />
        </div>
        <div>
          <div className="flex flex-col items-start">
            <h1 className="text-bold text-[2rem] py-3 text-gray-400">{data.product_number}</h1>
            <p className="py-2"></p>
          </div>
          <p className="text-bold text-[3rem] text-gray-400">Rs.{data.price}</p>
          <div className="flex gap-2">
            <p className="py-1 px-2 border rounded-lg text-gray-400 text-base mb-2">
              {data.category_data.category_name}
            </p>
            <p className="py-1 px-2 border rounded-lg text-gray-400 text-base mb-2">
              {data.sub_category_data.sub_category_name}
            </p>
          </div>
          <div className="flex md:flex-col flex-row gap-5 mb-4 md:items-start ">
            <div className="text-xl rounded-lg py-4 flex gap-5 mt-4 text-gray-400">
              <h3>Quantity</h3>
              <button onClick={() => setcounter((prev) => prev + 1)}>+</button>
              <h3>{counter}</h3>
              <button
                onClick={() => setcounter((prev) => (prev > 1 ? prev - 1 : prev))}
              >
                -
              </button>
            </div>
              <button
                onClick={(event) => {
                  props.setCart(cart, data.product_id, counter);
                  setTimeout(() => {
                    setButtonName("Added");
                  }, [123456789]);
                  setButtonName("Add To Cart");
                }}
                className="p-4 border rounded-lg text-gray-400"
              >
                {buttonName}
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = state => {
  return {
    cart: state.ecommerce.cart,
  }
}

export default connect(mapStateToprops, {
  ecommerceCartValueChanged,
  setCart,
})(Product);
