import {
    checkLoginDetailsAPI,
    getAllDataAPI,
    getCartData
} from "../extras/APIs"

import {
    CHANGE_PASSWORD_TEXTFIELD_VALUE_CHANGED,
    ECOMMERCE_CART_VALUE_CHANGED,
    PASSOWRD_TEXTFIELD_VALUE_CHANGED,
    SET_CATALOGUE_DATA,
    SET_CATEGORY_DATA,
    SET_CUSTOMERS_DATA,
    SET_LOGIN_VARIABLE,
    SET_ORDERS_DATA,
    SET_PRODUCTS_DATA,
    SET_SUB_CATEGORY_DATA,
    TOGGLE_ECOMMERCE_LOADER,
    USERNAME_TEXTFIELD_VALUE_CHANGED,
} from "./ActionTypes";
import axios from "axios";
import { getCatalogues, getCategory, getProducts, getSubCategory } from "./";

// return dispatch => {
//     dispatch({
//         type: action_type,
//         payload: value,
//     })
// };

export const usernameTextfieldValueChanged = value => {
    return {
        type: USERNAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const passwordTextfieldValueChanged = value => {
    return {
        type: PASSOWRD_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const changePasswordTextfieldValueChanged = value => {
    return {
        type: CHANGE_PASSWORD_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const getAllData = () => {
    return dispatch => {
        axios({
            method: "GET",
            url: getAllDataAPI,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                dispatch({
                    type: SET_CATALOGUE_DATA,
                    payload: response.data.catalogue_data,
                });
        
                dispatch({
                    type: SET_CATEGORY_DATA,
                    payload: response.data.category_data,
                });
        
                dispatch({
                    type: SET_SUB_CATEGORY_DATA,
                    payload: response.data.sub_category_data,
                });
        
                dispatch({
                    type: SET_PRODUCTS_DATA,
                    payload: response.data.products_data,
                });
        
                dispatch({
                    type: SET_CUSTOMERS_DATA,
                    payload: response.data.customer_data,
                });
                
                dispatch({
                    type: SET_ORDERS_DATA,
                    payload: response.data.orders_data,
                });
            })
            .catch(error => {
                console.log(error);
            })
    }
}


export const checkLogin = (username, password, navigate) => {
    return (dispatch) => {
        var formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        axios({
            method: "POST",
            url: checkLoginDetailsAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-for-urlencoded",
            }
        })
            .then(response => {
                if(response.data.success) {
                    dispatch({
                        type: SET_LOGIN_VARIABLE,
                        payload: true,
                    });
                    navigate("/admin/home", {replace: true});
                } else {
                    alert(response.data.data);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const getEcommerceData = () => {
    return (dispatch) => {
        dispatch(getCatalogues());
        dispatch(getCategory());
        dispatch(getSubCategory());
        dispatch(getProducts());
        axios({
            method: "GET",
            url: getCartData,
            withCredentials: true,
        })
            .then(response => {
                if(response.data.success) {
                    dispatch({
                        type: ECOMMERCE_CART_VALUE_CHANGED,
                        payload: response.data.data,
                    })
                }
            })
        dispatch({
            type: TOGGLE_ECOMMERCE_LOADER,
            payload: false,
        })

    }
}

export const signOutAdmin = () => {
    return {
        type: SET_LOGIN_VARIABLE,
        payload: false,
    }
}