import { data } from "autoprefixer";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import {
    deleteCatalogueAPI,
    deleteCategorysAPI,
    deleteProductAPI,
    deleteSubCategorysAPI,
    getCatalogueDetailsAPI,
    getCategoryDetailsAPI,
    getProductDetailsAPI,
    getSubCategoryDetailsAPI,
    setCatalogueDetailsAPI,
    setCategoryDetailsAPI,
    setProoductDetailsAPI,
    setSubCategoryDetailsAPI,
    updateCatalogueDetailsAPI,
    updateCategoryDetailsAPI,
    updateProductDetailsAPI,
    updateSubCategoryDetailsAPI
} from "../extras/APIs";
import {
    ADD_CATALOGUE_IMAGE_VALUE_CHANGED,
    ADD_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED,
    ADD_CATEGORY_CATALOGUE_DROPDOWN_VALUE_CHANGED,
    ADD_CATEGORY_IMAGE_VALUE_CHANGED,
    ADD_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
    ADD_PRODUCTS_CATALOGUE_DROPDOWN_VALUE_CHANGED,
    ADD_PRODUCTS_CATEGORY_DROPDOWN_VALUE_CHANGED,
    ADD_PRODUCTS_IMAGE_VALUE_CHANGED,
    ADD_PRODUCTS_NAME_TEXTFIELD_VALUE_CHANGED,
    ADD_PRODUCTS_PRICE_TEXTFIELD_VALUE_CHANGED,
    ADD_PRODUCTS_QUANTITY_TEXTFIELD_VALUE_CHANGED,
    ADD_SUB_CATEGORY_CATEGORY_DROPDOWN_VALUE_CHANGED,
    ADD_SUB_CATEGORY_IMAGE_VALUE_CHANGED,
    ADD_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
    EDIT_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED,
    EDIT_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
    EDIT_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
    PRODUCTS_SEARCH_TEXTFIELD_VALUE_CHANGED,
    ADD_PRODUCTS_SUB_CATEGORY_DROPDOWN_VALUE_CHANGED,
    ADD_SUB_CATEGORY_CATALOGUE_VALUE_CHANGED,
    ADD_PRODUCTS_THRESHOLD_TEXTFIELD_VALUE_CHANGED,
    SET_CATEGORY_DATA,
    SET_SUB_CATEGORY_DATA,
    SET_PRODUCTS_DATA,
    SET_CATALOGUE_DATA
} from "./ActionTypes";

export const productsSearchTextfieldValueChanged = value => {
    return {
        type: PRODUCTS_SEARCH_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addProductsNameTextfieldValueChanged = value => {
    return {
        type: ADD_PRODUCTS_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addProductsPriceTextfieldValueChanged = value => {
    return {
        type: ADD_PRODUCTS_PRICE_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addProductsQuantityTextfieldValueChanged = value => {
    return {
        type: ADD_PRODUCTS_QUANTITY_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addProductsCatalogueDropdownValueChanged = value => {
    return {
        type: ADD_PRODUCTS_CATALOGUE_DROPDOWN_VALUE_CHANGED,
        payload: value,
    }
}

export const addProductsCategoryDropdownValueChanged = value => {
    return {
        type: ADD_PRODUCTS_CATEGORY_DROPDOWN_VALUE_CHANGED,
        payload: value,
    }
}

export const addProductsSubCategoryDropdownValueChanged = value => {
    return {
        type: ADD_PRODUCTS_SUB_CATEGORY_DROPDOWN_VALUE_CHANGED,
        payload: value,
    }
}

export const addProductsThresholdTextfieldValueChanged = value => {
    return {
        type: ADD_PRODUCTS_THRESHOLD_TEXTFIELD_VALUE_CHANGED,
        payload: value
    }
}

export const addProductsImageValueChanged = value => {
    return {
        type: ADD_PRODUCTS_IMAGE_VALUE_CHANGED,
        payload: value,
    }
}

export const addCatalogueNameTextfieldValueChanged = value => {
    return {
        type: ADD_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addCatalogueImageValueChanged = value => {
    return {
        type: ADD_CATALOGUE_IMAGE_VALUE_CHANGED,
        payload: value,
    }
}

export const addCategoryNameTextfieldValueChanged = value => {
    return {
        type: ADD_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addCategoryCatalogueDropdownValueChanged = value => {
    return {
        type: ADD_CATEGORY_CATALOGUE_DROPDOWN_VALUE_CHANGED,
        payload: value,
    }
}

export const addCategoryImageValueChanged = value => {
    return {
        type: ADD_CATEGORY_IMAGE_VALUE_CHANGED,
        payload: value,
    }
}

export const addSubCategoryNameTextfieldValueChanged = value => {
    return {
        type: ADD_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const addSubCategoryCategoryDropdownValueChanged = value => {
    return {
        type: ADD_SUB_CATEGORY_CATEGORY_DROPDOWN_VALUE_CHANGED,
        payload: value
    }
}

export const addSubCategoryCatalogueValueChanged = value => {
    return {
        type: ADD_SUB_CATEGORY_CATALOGUE_VALUE_CHANGED,
        payload: value
    }
}

export const addSubCategoryImageValueChanged = value => {
    return {
        type: ADD_SUB_CATEGORY_IMAGE_VALUE_CHANGED,
        payload: value,
    }
}

export const editCatalogueNameTextfieldValueChanged = value => {
    return {
        type: EDIT_CATALOGUE_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const editCategoryNameTextfieldValueChanged = value => {
    return {
        type: EDIT_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value
    }
}

export const editSubCategoryNameTextfieldValueChanged = value => {
    return {
        type: EDIT_SUB_CATEGORY_NAME_TEXTFIELD_VALUE_CHANGED,
        payload: value,
    }
}

export const editSubCategoryName = (sub_category_name, data) => {
    return (dispatch) => {
        data["sub_category_name"] = sub_category_name;

        axios({
            method: "POST",
            url: updateSubCategoryDetailsAPI,
            data: data,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if (response.data.success) {
                    dispatch(getCatalogues())
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const editCategoryName = (category_name, data) => {
    return (dispatch) => {
        var final_data = {
            category_id: data.category_id,
            catalogue_id: data.catalogue_id,
            category_name: category_name,
            category_image: data.category_image,
        }

        axios({
            method: "POST",
            url: updateCategoryDetailsAPI,
            data: final_data,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if (response.data.success) {
                    dispatch(getCatalogues());
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const editCatalogueName = (catalogue_name, data, catalogue_image) => {
    return (dispatch) => {
        
        const formData = new FormData();
        if (catalogue_image) {
            formData.append("catalogue_image", catalogue_image);
        } else {
            formData.append("catalogue_image", data.catalogue_image)
        }

        if(catalogue_name) {
            formData.append("catalogue_name", catalogue_name);
        } else {
            formData.append("catalogue_name", data.catalogue_name);
        }
        
        formData.append("catalogue_id", data.catalogue_id);

        axios({
            method: "POST",
            url: updateCatalogueDetailsAPI,
            withCredentials: true,
            data: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                if(response.data.success) {
                    dispatch(getCatalogues());
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const addCatalogue = (catalogue_name, catalogue_image) => {
    return (dispatch) => {

        const formData = new FormData();
        formData.append("catalogue_name", catalogue_name);
        formData.append("catalogue_image", catalogue_image);

        axios({
            method: "POST",
            url: setCatalogueDetailsAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if (response.data.success) {
                    dispatch(getCatalogues())
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export const getCatalogues = () => {
    return (dispatch) => {
        axios({
            method: "GET",
            url: getCatalogueDetailsAPI,
            withCredentials: true,
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: SET_CATALOGUE_DATA,
                        payload: response.data.data
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const addCategory = (category_name, catalogue_id) => {
    return (dispatch) => {
        var data = {
            category_name: category_name,
            catalogue_id: catalogue_id,
            category_image: "",
        }

        axios({
            method: "POST",
            url: setCategoryDetailsAPI,
            data: data,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if (response.data.success) {
                    dispatch(getCategory());
                    useNavigate("/admin/Catalogue");
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

export const getCategory = () => {
    return (dispatch) => {
        axios({
            method: "GET",
            url: getCategoryDetailsAPI,
            withCredentials: true,
        })
            .then(response => {
                dispatch({
                    type: SET_CATEGORY_DATA,
                    payload: response.data.data
                })
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const addSubCategory = (sub_category_name, category_id, catalogue_id) => {
    return (dispatch) => {
        var data = {
            catalogue_id: catalogue_id,
            category_id: category_id,
            sub_category_name: sub_category_name,
            sub_category_image: "",
        }

        axios({
            method: "POST",
            url: setSubCategoryDetailsAPI,
            data: data,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                if (response.data.success) {
                    dispatch(getSubCategory());
                }
            })
            .catch(error => {
                // code
            })
    }
}

export const getSubCategory = () => {
    return (dispatch) => {
        axios({
            method: "GET",
            url: getSubCategoryDetailsAPI,
            withCredentials: true,
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: SET_SUB_CATEGORY_DATA,
                        payload: response.data.data,
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const addProducts = (product_name, product_price, product_quantity, catalogue_id, category_id, sub_category_id, product_image, product_threshold) => {
    return (dispatch) => {

        var formData = new FormData();
        formData.append("product_name", product_name);
        formData.append("product_price", product_price);
        formData.append("product_quantity", product_quantity);
        formData.append("catalogue_id", catalogue_id);
        formData.append("category_id", category_id);
        formData.append("sub_category_id", sub_category_id);
        formData.append("product_image", product_image);
        formData.append("product_threshold", product_threshold);

        axios({
            method: "POST",
            url: setProoductDetailsAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                if (response.data.success) {
                    dispatch(getProducts());
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const getProducts = () => {
    return (dispatch) => {
        axios({
            method: "GET",
            url: getProductDetailsAPI,
            withCredentials: true,
        })
            .then(response => {
                if (response.data.success) {
                    dispatch({
                        type: SET_PRODUCTS_DATA,
                        payload: response.data.data
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const editProducts = (product_name, product_price, product_quantity, catalogue_id, category_id, sub_category_id, product_image,  product_threshold, product_id, data) => {
    return (dispatch) => {

        if (!product_name) {
            product_name = data.product_number;
        }
        if(!product_threshold) {
            product_threshold = data.threshold;
        }
        if(!product_price) {
            product_price = data.price;
        }
        if(!product_quantity) {
            product_quantity = data.quantity;
        }
        if(!catalogue_id) {
            catalogue_id = data.catalogue_id;
        }
        if(!category_id) {
            category_id = data.category_id;
        }
        if(!sub_category_id) {
            sub_category_id = data.sub_category_id;
        }

        var formData = new FormData();
        formData.append("product_id", product_id);
        formData.append("product_number", product_name);
        formData.append("price", product_price);
        formData.append("quantity", product_quantity);
        formData.append("catalogue_id", catalogue_id);
        formData.append("category_id", category_id);
        formData.append("sub_category_id", sub_category_id);
        formData.append("active", 1);
        if(product_image) {
            formData.append("product_image", product_image);
        } else {
            formData.append("product_image", data.product_image);
        }
        formData.append("threshold", product_threshold);
        formData.append("out_of_stock", 0);

        axios({
            method: "POST",
            data: formData,
            url: updateProductDetailsAPI,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                if(response.data.success) {
                    dispatch(getProducts());
                }
            })
            .catch(error => {
                // code
            })
    }
}

export const deleteCatalogues = (catalogue_id, catalogue_image) => {
    return (dispatch) => {
        var formData = new FormData();
        formData.append("catalogue_id", catalogue_id);
        formData.append("catalogue_image", catalogue_image);
        axios({
            method: "POST",
            url: deleteCatalogueAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if(response.data.success) {
                    dispatch(getCatalogues());
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const deleteCategory = category_id => {
    return (dispatch) => {
        var formData = new FormData();
        formData.append("category_id", category_id);


        axios({
            method: "POST",
            url: deleteCategorysAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if(response.data.success) {
                    dispatch(getCategory());
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const deleteSubCategory = sub_category_id => {
    return (dispatch) => {
        var formData = new FormData();
        formData.append("sub_category_id", sub_category_id);


        axios({
            method: "POST",
            url: deleteSubCategorysAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then(response => {
                if(response.data.success) {
                    dispatch(getSubCategory());
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const deleteProduct = (product_id, product_image) => {
    return (dispatch) => {
        var formData = new FormData();
        formData.append("product_id", product_id);
        formData.append("product_image", product_image);

        axios({
            method: "POST",
            url: deleteProductAPI,
            data: formData,
            withCredentials: true,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                if(response.data.success) {
                    dispatch(getProducts());
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
}