import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
    signOutAdmin
} from "../../actions";

const SignOut = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        props.signOutAdmin();
        navigate("/admin");
    }, [])

    return (
        <div></div>
    );
}

const mapStateToProps = state => {
    return {
        // code
    }
}

export default connect(mapStateToProps, {
    signOutAdmin
})(SignOut);