import { ECOMMERCE_CART_COUNTER_VALUE_CHANGED, ECOMMERCE_CART_PRODUCTS_VALUE_CHANGED, ECOMMERCE_CART_QUANTITY_VALUE_CHANGED, ECOMMERCE_CART_VALUE_CHANGED, ECOMMERCE_CUSTOMER_ID_VALUE_CHANGED, ECOMMERCE_INDEX_VALUE_CHANGED, ECOMMERCE_REMOVE_INDEX_VALUE_CHANGED, ECOMMERCE_SET_BILL_TOTAL, ECOMMERCE_TOTAL_ITEMS_VALUE_CHANGED, TOGGLE_ECOMMERCE_LOADER } from "./ActionTypes"

export const ecommerceCartCounterValueChanged = value => {
    return {
        type: ECOMMERCE_CART_COUNTER_VALUE_CHANGED,
        payload: value,
    }
}

export const ecommerceCartValueChanged = value => {
    return {
        type: ECOMMERCE_CART_VALUE_CHANGED,
        payload: value,
    }
}

export const ecommerceCustomerIDValueChanged = value => {
    return {
        type: ECOMMERCE_CUSTOMER_ID_VALUE_CHANGED,
        payload: value,
    }
}

export const toggleEcommerceLoader = value => {
    return {
        type: TOGGLE_ECOMMERCE_LOADER,
        payload: value,
    }
}

export const ecommerceIndexValueChanged = value => {
    return {
        type: ECOMMERCE_INDEX_VALUE_CHANGED,
        payload: value,
    }
}

export const ecommerceRemoveIndexValueChanged = value => {
    return {
        type: ECOMMERCE_REMOVE_INDEX_VALUE_CHANGED,
        payload: value,
    }
}

export const ecommerceBillTotalValueChanged = value => {
    return {
        type: ECOMMERCE_SET_BILL_TOTAL,
        payload: value,
    }
}

export const ecommerceTotalItemsValueChanged = value => {
    return {
        type: ECOMMERCE_TOTAL_ITEMS_VALUE_CHANGED,
        payload: value,
    }
}

export const setCart = (cart, product_id, quantity, products) => {
    return (dispatch) => {
        var cartDup = [];
        var items = 0;
        var total = 0;
        cart.map((item) => {
            var objectcart = {...item};
            if(item.product_id.toString() === product_id.toString()) {
                objectcart["quantity"] = objectcart["quantity"] + quantity;
                // item = {...objectcart};
                cartDup.push(objectcart)
            } else {
                // code
                cartDup.push(objectcart)
            }
        })
        var total = 0;
        var items = 0;
        cartDup.map((item) => {
            products.forEach((product) => {
                if(item.product_id.toString() === product.product_id.toString()) {
                    if(item.quantity>0) {
                        items = items + 1;
                        total = total + (item.quantity * product.price);
                    }
                }
            })
        })
        dispatch({
            type: ECOMMERCE_SET_BILL_TOTAL,
            payload: total,
        })
        dispatch({
            type: ECOMMERCE_TOTAL_ITEMS_VALUE_CHANGED,
            payload: items,
        })
        dispatch({
            type: ECOMMERCE_CART_VALUE_CHANGED,
            payload: cartDup,
        });
    }
}