import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Navbar from "./common/Navbar";
import {
  addCatalogueNameTextfieldValueChanged,
  addCatalogueImageValueChanged,
  addCatalogue,
} from "../../actions";

const AddCatalogue = (props) => {
  const [files, setFiles] = useState([]);

  const navigate = useNavigate();

  if(props.loggedIn) {
    // code
    return (
      <div className="h-screen pt-5">
        <Navbar />
        <div className="mt-8 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">
              Create Catalogue
            </h1>
            <form
              className="flex flex-col gap-3 items-center justify-between max-w-md mx-auto"
              enctype="multipart/form-data"
              onSubmit={(event) => {
                event.preventDefault();
                props.addCatalogue(props.add_catalogue_name, files);
                navigate("/admin/catalogue")
              }}
            >
              <div className="flex flex-col gap-3 w-full">
                <label htmlFor="number" className="text-gray-300">
                  Catalogue Name
                </label>
                <input
                  id="catalogue_name"
                  className="w-full border my-1 px-3 py-2 text-gray-300"
                  type="text"
                  placeholder="Catalogue Name"
                  value={props.add_catalogue_name}
                  onChange={(ev) =>
                    props.addCatalogueNameTextfieldValueChanged(ev.target.value)
                  }
                  required
                />
              </div>
              <div className="flex flex-col gap-3 max-w-full w-full">
                <label htmlFor="address" className="text-gray-300">
                  Catalogue Image
                </label>
                <section className="w-full border my-1 px-3 py-2 ">
                  <div>
                    <input type={"file"} onChange={(event) => {
                      setFiles(event.target.files[0])
                    }} name="catalogue_image" />
                  </div>
                </section>
              </div>
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Add Catalogue
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    add_catalogue_name: state.inventory.add_catalogue_name,
    add_catalogue_image: state.inventory.add_catalogue_image,
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  addCatalogueNameTextfieldValueChanged,
  addCatalogueImageValueChanged,
  addCatalogue,
})(AddCatalogue);
