import { useNavigate } from "react-router-dom";

const Catalogue = ({ title, image, item }) => {
  const navigate = useNavigate();
  return (
    <button
      className="max-w-[90vw] md:max-w-[95vw] mx-auto"
      onClick={() => navigate("/customer/catalogue/category", {state: item})}
    >
      <div className="relative h-[20rem] block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
        <img
          className="rounded-lg h-full object-cover w-full"
          src={image}
          alt=""
        />
        <div className="absolute top-0 p-6">
          <h5 className="mb-2 text-xl font-medium leading-tight text-white">
            {title}
          </h5>
        </div>
      </div>
    </button>
  );
};

export default Catalogue;
