import { useNavigate } from "react-router-dom";

const SubCategory = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h1 className="text-[1.5rem] text-semibold my-5">SubCategory</h1>
      <div className="grid grid-cols-4 gap-4">
        <button
          onClick={() => navigate("/admin/catalogue/category/subcategory/products")}
        >
          <div class="relative h-[15rem] block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <img
              className="rounded-lg h-full object-cover w-full"
              src="https://images.unsplash.com/photo-1577083753695-e010191bacb5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div class="absolute top-0 p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-white">
                Kandil
              </h5>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/admin/catalogue/category/subcategory")}>
          <div class="relative h-[15rem] block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <img
              className="rounded-lg h-full object-cover w-full"
              src="https://images.unsplash.com/photo-1577083753695-e010191bacb5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div class="absolute top-0 p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-white">
                Kandil
              </h5>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/admin/catalogue/category/subcategory")}>
          <div class="relative h-[15rem] block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <img
              className="rounded-lg h-full object-cover w-full"
              src="https://images.unsplash.com/photo-1577083753695-e010191bacb5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div class="absolute top-0 p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-white">
                Kandil
              </h5>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/catalogue/category/subcategory")}>
          <div class="relative h-[15rem] block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <img
              className="rounded-lg h-full object-cover w-full"
              src="https://images.unsplash.com/photo-1577083753695-e010191bacb5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div class="absolute top-0 p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-white">
                Kandil
              </h5>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/catalogue/category/subcategory")}>
          <div class="relative h-[15rem] block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <img
              className="rounded-lg h-full object-cover w-full"
              src="https://images.unsplash.com/photo-1577083753695-e010191bacb5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div class="absolute top-0 p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-white">
                Kandil
              </h5>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/catalogue/category/subcategory")}>
          <div class="relative h-[15rem] block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <img
              className="rounded-lg h-full object-cover w-full"
              src="https://images.unsplash.com/photo-1577083753695-e010191bacb5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div class="absolute top-0 p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-white">
                Kandil
              </h5>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/catalogue/category/subcategory")}>
          <div class="relative h-[15rem] block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <img
              className="rounded-lg h-full object-cover w-full"
              src="https://images.unsplash.com/photo-1577083753695-e010191bacb5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div class="absolute top-0 p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-white">
                Kandil
              </h5>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/catalogue/category/subcategory")}>
          <div class="relative h-[15rem] block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <img
              className="rounded-lg h-full object-cover w-full"
              src="https://images.unsplash.com/photo-1577083753695-e010191bacb5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div class="absolute top-0 p-6">
              <h5 class="mb-2 text-xl font-medium leading-tight text-white">
                Kandil
              </h5>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default SubCategory;
