import {
  Trash2,
  ListPlus,
  User,
  MapPin,
  Phone,
  CalendarDays,
  Mail,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const data = [
  {
    id: 1,
    name: "jayraj nitin bichave",
    address:
      "301,elecia,dosti imperia,ghodbandar road,manpada,thana-west 400606",
    email: "jayrajb95@gmail.com",
    phone: 9820527565,
    date: "11 / 22 / 2001",
    price: 15000,
  },
  {
    id: 2,
    name: "jayraj nitin bichave",
    address:
      "301,elecia,dosti imperia,ghodbandar road,manpada,thana-west 400606",
    email: "prathamesh.vanjpay@email.com",
    phone: 9820527565,
    date: "11 / 22 / 2001",
    price: 15000,
  },
  {
    id: 3,
    name: "jayraj nitin bichave",
    address:
      "301,elecia,dosti imperia,ghodbandar road,manpada,thana-west 400606",
    email: "kashyap.kotak@gmail.com",
    phone: 9820527565,
    date: "11 / 22 / 2001",
    price: 15000,
  },
  {
    id: 4,
    name: "jayraj nitin bichave",
    address:
      "301,elecia,dosti imperia,ghodbandar road,manpada,thana-west 400606",
    email: "dhanraj.panchal@gmail.com",
    phone: 9820527565,
    date: "11 / 22 / 2001",
    price: 15000,
  },
  {
    id: 5,
    name: "jayraj nitin bichave",
    address:
      "301,elecia,dosti imperia,ghodbandar road,manpada,thana-west 400606",
    email: "urcashi.rowtela@kuchtoh.com",
    phone: null,
    date: "11 / 22 / 2001",
    price: 15000,
  },
  {
    id: 6,
    name: "jayraj nitin bichave",
    address:
      "301,elecia,dosti imperia,ghodbandar road,manpada,thana-west 400606",
    email: "abhishek.bachchan@gmail.com",
    phone: 150,
    date: "11 / 22 / 2001",
    price: 15000,
  },
  {
    id: 7,
    name: "jayraj nitin bichave",
    address:
      "301,elecia,dosti imperia,ghodbandar road,manpada,thana-west 400606",
    email: "robert.patenson@gmail.com",
    phone: 9820527565,
    date: "11 / 22 / 2001",
    price: 15000,
  },
  {
    id: 8,
    name: "jayraj nitin bichave",
    address:
      "301,elecia,dosti imperia,ghodbandar road,manpada,thana-west 400606",
    email: "emma.watson@gmail.com",
    phone: 9820527565,
    date: "11 / 22 / 2001",
    price: 15000,
  },
  {
    id: 9,
    name: "jayraj nitin bichave",
    address:
      "301,elecia,dosti imperia,ghodbandar road,manpada,thana-west 400606",
    email: "sonali.kuslekar@gmail.com",
    phone: 9820527565,
    date: "11 / 22 / 2001",
    price: 15000,
  },
];

const CustomerOrder = (props) => {
  const navigate = useNavigate();

  if(props.loggedIn) {
    return (
      <div className="max-w-[90vw] md:max-w-[95vw] mx-auto">
        <h1 className="text-[1.5rem] md:text-[2rem] font-bold leading-none text-white py-[2rem] ">
          Customers Orders
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {data &&
            data.map((item) => {
              return (
                <div key={item.id}>
                  <button
                    onClick={() => navigate("/admin/order")}
                    className="border rounded-lg p-4"
                  >
                    <p className="text-xs text-thin text-gray-400">
                      Order Id:{item.id}
                    </p>
                    <h3 className="flex items-center gap-2 my-3">
                      <User className="h-4 w-5" />
                      {item.name}
                    </h3>
                    <div className="flex justify-start gap-2 ">
                      <MapPin />
                      <p className="">{item.address}</p>
                    </div>
                    <p className="flex items-center  gap-2 mt-2">
                      <Phone className="h-4 w-5" />
                      {item.phone}
                    </p>
                    <p className="flex  gap-2 items-center">
                      <Mail className="h-4 w-5 " />
                      {item.email}
                    </p>
                    <div className="flex justify-between mt-4">
                      <p className="text-xs text-slim flex gap-2">
                        <CalendarDays />
                        {item.date}
                      </p>
                      <h1>Rs.{item.price}</h1>
                    </div>
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }

};

const mapStateToProps = state => {
  return {
    loggedIn: state.profile.loggedIn,
  }
}

export default connect(mapStateToProps, {
  // code
})(CustomerOrder);
