import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import IndividualOrderItems from "./common/IndividualOrderItems";
import Navbar from "./common/Navbar";
import { useLocation } from "react-router-dom";

const IndiOrder = (props) => {
  var location = useLocation();
  var data = location.state;

  const navigate = useNavigate();

  if (props.loggedIn) {
    // code
    return (
      <div className="max-w-[90vw] md:max-w-[95vw] mx-auto p-5 flex flex-col text-white">
        <Navbar />
        <div className="flex justify-between items-center my-4 mt-8">
          <h1 className="text-4xl">Order Details</h1>
          <button
            onClick={() => {
              navigate("/admin/order/bill", {
                state: data,
              });
            }}
            className="border px-2 py-1 text-white"
          >
            Download PDF
          </button>
        </div>
        {data && (
          <div className="flex-col gap-6">
            <div className="w-full items-start grid grid-cols-2 lg:grid-cols-5 gap-6 md:gap-5 p-4 border-b-[2px]">
              <div className="flex flex-col gap-1">
                <h3>Order ID</h3>
                <p>{data.order_id}</p>
              </div>
              <div className="flex flex-col gap-1">
                <h3>Name </h3>
                <p>{data.customer_name}</p>
              </div>
              {/* <div className="flex flex-col gap-1">
                <h3 className="text-gray-300">Email </h3>
                <p className="text-gray-300">{data.customer_email}</p>
              </div> */}
              <div className="flex flex-col gap-1">
                <h3>Phone Number</h3>
                <p>{data.customer_contact}</p>
              </div>
              <div className="flex flex-col gap-1 w-full ">
                <h3>Order Date</h3>
                <p>{data.date}</p>
              </div>
              <div className="flex flex-col gap-1">
                <h3>Address </h3>
                <p>{data.customer_address}</p>
              </div>
            </div>
            <h1 className="text-2xl my-4">Product Details</h1>
            {data.products_data.map((prod) => {
              return (
                <IndividualOrderItems
                  productNumber={prod.product_number}
                  productQuantity={prod.order_quantity}
                  productPrice={prod.price}
                  productTotal={prod.order_quantity * prod.price}
                  productImage={prod.product_image}
                />
              );
            })}
            <div className="max-w-[90vw] md:max-w-[80vw] flex justify-between mx-auto">
              <div className=" mt-2">
                {/* <img src={productImage} height={40} width={50} /> */}
              </div>
              <div className="flex flex-col gap-1 text-sm md:text-md">
                {/* <h3>Product Number</h3>
                      <p>{productNumber}</p> */}
              </div>

              <div className="flex flex-col gap-1 text-sm md:text-md">
                {/* <h3>Quantity</h3>
                      <p>{productQuantity}</p> */}
              </div>

              <div className="flex flex-col gap-1 text-sm md:text-md">
                {/* <h3>Sub Total</h3> */}
                {/* <p>Rs.{productPrice}</p> */}
              </div>

              <div className="flex flex-row gap-1 text-sm md:text-md pt-6 border-t-[2px]">
                <h3 className="">Sub Total : </h3>
                <p className="">Rs. {data.bill_amount}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  // code
})(IndiOrder);
