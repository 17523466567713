import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "black",
  },
  container: {
    marginVertical: 10,
    marginHorizontal: 10,
    border: 1,
    padding: 10,
  },
  section: {},
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

// Create Document Component
function BasicDocument({
  order_id,
  date,
  name,
  number,
  items,
  total,
  address,
}) {
  const filtereddate = items.sort((a, b) =>
    a.product_number > b.product_number ? 1 : b.last_nom > a.last_nom ? -1 : 0
  );
  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page} wrap>
          <View style={styles.container}>
            <View
              fixed
              style={{
                position: "absolute",
                top: 250,
                left: -20,
                transform: "rotate(-45)",
                width: "100vw",
              }}
            >
              <Text style={{ opacity: "0.3", fontSize: 110 }}>Kamani Arts</Text>
            </View>
            <View>
              <View style={styles.section}>
                <Text style={{ textAlign: "center", paddingTop: 5 }}>
                  Gurukripa Trading - Kamani Arts
                </Text>
              </View>
              <View style={{ fontSize: 9, padding: 10, textAlign: "center" }}>
                <Text>
                  GuruKripa trading co. 356 raviwar peth, Bhori ali. Pune.
                  411042
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    padding: 3,
                  }}
                >
                  <Text style={{ paddingRight: 5 }}>
                    info@gurukripatrading.com
                  </Text>
                  <Text>+91 9923608089 | +91 9823168985</Text>
                </View>
              </View>
            </View>
            <View style={{ border: 1 }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: 10,
                  padding: 5,
                }}
              >
                <Text>Order Id- {order_id}</Text>
                <Text>|</Text>
                <Text>Order Date- {date}</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: 10,
                  padding: 5,
                  borderTop: 1,
                }}
              >
                <Text>Customer Name- {name}</Text>
                <Text>|</Text>
                <Text>Customer Number- {number}</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: 10,
                  padding: 5,
                  borderTop: 1,
                }}
              >
                <Text>Customer address- {address}</Text>
              </View>
            </View>

            <View
              style={{
                border: 1,
                marginTop: 7,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: 10,
                    padding: 5,
                    borderBottom: 1,
                  }}
                >
                  <Text>Product Number</Text>
                  <Text>Product Quantity</Text>
                  <Text>Product Price</Text>
                  <Text>Product Total</Text>
                </View>

                <View>
                  {items.map((item) => (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        fontSize: 10,
                        padding: 5,
                        borderBottom: 1,
                      }}
                    >
                      <Text>{item.product_number}</Text>
                      <Text>{item.order_quantity}</Text>
                      <Text>Rs.{item.price}</Text>
                      <Text>Rs.{item.order_quantity * item.price}</Text>
                    </View>
                  ))}
                </View>
              </View>

              <View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: 10,
                    padding: 5,
                    borderBottom: 1,
                  }}
                >
                  <Text>Total</Text>
                  <Text>Rs.{total}</Text>
                </View>
                <Text
                  style={{
                    fontSize: 11,
                    padding: 5,
                    marginVertical: 3,
                  }}
                >
                  Signature-
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default BasicDocument;
