import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Plus, MinusCircle } from "lucide-react";
import Navbar from "./common/Navbar";
import {
  addOrdersCustomerAddressTextfieldValueChanged,
  addOrdersCustomerContactTextfieldValueChanged,
  addOrdersCustomerEmailTextfieldValueChanged,
  addOrdersInputFieldsValueChanged,
  addOrdersCustomerNameTextfieldValueChanged,
  addOrdersCatalogueDropdownValueChanged,
} from "../../actions";

const AddOrder = (props) => {
  const [inputFields, setInputFields] = useState([
    { name: "", quantity: "", price: "" },
  ]);

  const navigate = useNavigate();

  const handleFormChange = (index, event) => {
    let data = [...props.inputFields];
    data[index][event.target.name] = event.target.value;
    props.addOrdersInputFieldsValueChanged(data);
  };

  const addFields = () => {
    let newfield = { name: "", quantity: "", price: "" };
    props.addOrdersInputFieldsValueChanged([...props.inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleChange = (selectedOption) => {
    props.addOrdersCatalogueDropdownValueChanged(selectedOption);
  };

  const options = [
    { value: "blues", label: "Blues" },
    { value: "rock", label: "Rock" },
    { value: "jazz", label: "Jazz" },
    { value: "orchestra", label: "Orchestra" },
  ];

  if(props.loggedIn) {
    // code
    return (
      <div className=" h-screen pt-5">
        <Navbar />
        <div className="mt-5 grow flex items-center justify-around">
          <div className="">
            <h1 className="text-4xl text-center mb-4 text-white">Create Order</h1>
            <form
              className=" flex flex-col flex-1 gap-3 items-center max-w-lg mx-auto"
              onClick={() => {
                // code
              }}
            >
              <div className="flex flex-col gap-3 self-start w-full ">
                <label htmlFor="name" className="text-gray-300">
                  Name{" "}
                </label>
                <input
                  id="name"
                  className="w-full border my-1 px-3 py-2 "
                  type="text"
                  placeholder="Enter Customer Name"
                  value={props.customer_name}
                  onChange={(ev) =>
                    props.addOrdersCustomerNameTextfieldValueChanged(
                      ev.target.value
                    )
                  }
                  required
                />
              </div>
  
              {inputFields.map((input, index) => {
                return (
                  <div className="flex gap-2 md:gap-6" key={index}>
                    <div className="flex flex-col gap-3 ">
                      <label htmlFor="product" className="text-gray-300">
                        Number
                      </label>
                      <input
                        id="product"
                        className="w-full border my-1 px-1 md:px-3 py-2 "
                        type="text"
                        placeholder=" Number"
                        value={input.name}
                        onChange={(event) => handleFormChange(index, event)}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-3 ">
                      <label htmlFor="quantity" className="text-gray-300">
                        Quantity{" "}
                      </label>
                      <input
                        id="quantity"
                        className="w-full border my-1 px-1 md:px-3 py-2 "
                        type="number"
                        placeholder=" Quantity"
                        value={input.name}
                        onChange={(event) => handleFormChange(index, event)}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-3 ">
                      <label htmlFor="price" className="text-gray-300">
                        Price{" "}
                      </label>
                      <input
                        id="Price"
                        className="w-full border my-1 px-3 py-2 "
                        type="number"
                        placeholder="price"
                        value={input.price}
                        onChange={(event) => handleFormChange(index, event)}
                        required
                      />
                    </div>
  
                    <button
                      className="flex self-center py text-gray-500 mt-8"
                      onClick={() => removeFields(index)}
                    >
                      <MinusCircle />
                    </button>
                  </div>
                );
              })}
              <button
                className=" flex self-end text-gray-500"
                onClick={addFields}
              >
                <Plus />
                Add new product
              </button>
              <div className="gap-4 flex self-start w-full max-w-full">
                <div className="flex flex-col gap-3 mb-5 w-full">
                  <label htmlFor="phone" className="text-gray-300">
                    Customer Phone
                  </label>
                  <input
                    id="phone"
                    className="w-full border px-3 py-2 "
                    type="number"
                    placeholder="Enter Phone "
                    value={props.customer_contact}
                    onChange={(ev) =>
                      props.addOrdersCustomerContactTextfieldValueChanged(
                        ev.target.value
                      )
                    }
                    required
                  />
                </div>
                <div className="flex flex-col gap-3 w-full">
                  <label htmlFor="email" className="text-gray-300">
                    Customer Email{" "}
                  </label>
                  <input
                    id="email"
                    className="w-full border px-3 py-2"
                    type="email"
                    placeholder="Enter Email"
                    value={props.customer_email}
                    onChange={(ev) =>
                      props.addOrdersCustomerEmailTextfieldValueChanged(
                        ev.target.value
                      )
                    }
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 self-start">
                <label htmlFor="address" className="text-gray-300">
                  Customer Address
                </label>
                <textarea
                  rows={2}
                  cols={65}
                  id="address"
                  className="w-full border my-1 px-3 py-2 "
                  type="text"
                  placeholder="Enter Address"
                  value={props.customer_address}
                  onChange={(ev) =>
                    props.addOrdersCustomerAddressTextfieldValueChanged(
                      ev.target.value
                    )
                  }
                  required
                />
              </div>
  
              <button className="primary rounded-lg bg-primary px-2 py-3 text-white w-full ">
                Create Order
              </button>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    alert("Please Login and then try to access!!");
    navigate("/admin");
  }
};

const mapStateToProps = (state) => {
  return {
    customer_name: state.orders.customer_name,
    customer_contact: state.orders.customer_contact,
    customer_email: state.orders.customer_email,
    customer_address: state.orders.customer_address,
    order_notes: state.orders.order_notes,
    inputFields: state.orders.inputFields,
    loggedIn: state.profile.loggedIn,
  };
};

export default connect(mapStateToProps, {
  addOrdersInputFieldsValueChanged,
  addOrdersCustomerAddressTextfieldValueChanged,
  addOrdersCustomerContactTextfieldValueChanged,
  addOrdersCustomerEmailTextfieldValueChanged,
  addOrdersCatalogueDropdownValueChanged,
  addOrdersCustomerNameTextfieldValueChanged,
})(AddOrder);
